import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import "./assessMeComponents.scss";
import { constants } from "../../../constants/constants";
import { useEffect, useState } from "react";
import { TextareaAutosize } from "@mui/base";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";

export default function OWQComponent(props: {
  markAnswer: any;
  question: any;
  nextQuestion: any;
  prevQuestion: any;
  answeredData:any;
  currQnindex:any;
  reRender:any;
  qnStartTime:any;

}) {
  useEffect(() => {}, [props.question.q]);
  useEffect(()=>{

    console.log(props.answeredData);
    setMarkForReview(props.answeredData[props.currQnindex].marked?true : false);
    let currQn = props.answeredData[props.currQnindex];
    if (currQn && currQn.answer&& currQn.answer.selectedanswer!==undefined) {
      console.log("ansered aan",currQn.answer.selectedanswer);
      setAnswer(currQn.answer.selectedanswer)
    }
    else {
      console.log('answered alla')
      setAnswer("")
    }

  },[props.question])
  var [skipped, setSkipped] = useState(false);
  var [answer, setAnswer] = useState("");
  var [marked, setMarkForReview] = useState(false);

  function changeQuestion(param: boolean) {
    // param true = next question
    // param false = previous question
    if (param) {
      let packageId = getFromLocalStorage("packageId");

      let apiData = {
        answer: {
          question_id: props.question.content_uuid,
          totaltime: 0,
          package_id: packageId,
          question_type: "OneWordQuestion",
          skipped: answer === ''?true:false,
          selectedanswer: answer,
        },
      };
      console.log(apiData, answer)
      props.nextQuestion(apiData, marked);
    } else {
      props.prevQuestion();
    }
  }

  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(event.target.value, id);
    setMarkForReview(marked => !marked)
    console.log(marked)
  };

  function createMarkup(value: string) {
    return {
      __html: value,
    };
  }
  function clear(){
    setAnswer("")
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q)}
          item
        ></Grid>
        <Grid
          container
          item
          xs={7}
          sm={9}
          lg={10}
          style={{ height: "30vh", marginTop: "20vh" }}
        >
          <Grid item xs={12}>
            <TextareaAutosize
              style={{ width: "55vw" }}
              minRows={5}
              placeholder="Answer Here"
              className="input"
              value={answer}
              onChange={(event) => {
                setAnswer(event.target.value);
              }}
            />
            {/* <input
              type="text"
              placeholder="Answer Here"
              className="input"
            ></input> */}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <Checkbox
            onChange={(e) => {
              markForReview(e, props.question);
            }}
            checked={marked}
            defaultChecked={marked}
          />
          <span className="questionNo">
            {" "}
            Mark for review 
          </span>
          {/* <FormControlLabel
        value="end"
        control={<Checkbox />}
        label="End"
        labelPlacement="end"
      /> */}
        </Grid>
        <Grid container item xs={2.5}>
          <span className="clearButton" onClick={()=> clear()}>Clear</span>
        </Grid>
        <Grid container item xs={1.5}>
          <span
            className="nextButton previous"
            onClick={() => changeQuestion(false)}
          >
            Previous
          </span>
        </Grid>
        <Grid container item xs={2}>
          <span className="nextButton" onClick={() => changeQuestion(true)}>
            Next
          </span>
        </Grid>
      </Grid>
    </>
  );
}
