/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022\
 * Api Services page
 * */
/**

This component renders an Alert using the MUI (Material-UI) library.
It receives props that control the visibility and content of the Alert.

The props include:
* alertShow: a boolean value indicating whether the Alert should be displayed
* setAlertShow: a function to update the state of the Alert visibility
* errorType: a string representing the type of the Alert (e.g., error, warning, info, success)
* notificationMessage: the message to be displayed within the Alert
* handleClose: a function to handle the closing of the Alert

The AlertService component is typically used within other components that need to show
notifications or error messages to the user. By passing the appropriate props, different
types of Alerts can be displayed with the desired messages and styles.
*/

import { Alert, Snackbar } from "@mui/material";
   
export default function AlertService(props: {
  alertShow: boolean;
  setAlertShow: any;
  errorType: string;
  notificationMessage: string;
  handleClose: any;
}) {
  return (
    <Snackbar
      open={props.alertShow}
      autoHideDuration={6000}
      onClose={props.handleClose}
    >
      {props.errorType === "error" ? (
        <Alert severity="error">{props.notificationMessage}</Alert>
      ) : props.errorType === "warning" ? (
        <Alert severity="warning">{props.notificationMessage}</Alert>
      ) : props.errorType === "info" ? (
        <Alert severity="info">{props.notificationMessage}</Alert>
      ) : (
        <Alert severity="success">{props.notificationMessage}</Alert>
      )}
    </Snackbar>
  );
}
