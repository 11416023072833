/**
 * This page contains the subject tags (Icons, Subject label and styles for each subjects.)
 * imported in dashboard and where ever subject image and name are required as per UI.(Learn on Assess me etc..)
 */

import React from "react";

import "./uiComponents.scss";

import physics from "../../assets/icons/Physics.png";
import mathematics from "../../assets/icons/Mathematics.png";
import chemistry from "../../assets/icons/Chemistry.png";
import biology from "../../assets/icons/Biology.png";
import englishGrammar from "../../assets/icons/English_Grammar.png";
import english  from "../../assets/icons/English.png";
import science  from "../../assets/icons/science.png";
import evs  from "../../assets/icons/evs.png";

import { ReactComponent as Malayalam } from "../../assets/icons/malayalam.svg";
import { ReactComponent as Malayalam2 } from "../../assets/icons/malayalam2.svg";
import { ReactComponent as Hindi } from "../../assets/icons/hindi.svg";
import { ReactComponent as Scale } from "../../assets/icons/scale.svg";
import { ReactComponent as ComputerLogo } from "../../assets/icons/computer.svg";
import { ReactComponent as SocialScience } from "../../assets/icons/socialScience.svg";

export function SubjectTags(props: any) {
  var subject = props.subject.toLowerCase();
  switch (subject) {
    case "physics":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag">
            {/* <Physics /> */}
            <img src={physics} alt={props.subject} style={{ width: "80px" }} />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "malayalam":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag layer">
            <Malayalam />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "malayalam2":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag layer malayalam2">
            <Malayalam2 />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "hindi":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag layer hindi">
            <Hindi />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "mathematics":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag">
            <Scale />
            <img
              src={mathematics}
              alt="Mathematics"
              style={{ width: "80px" }}
            />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "information technology":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag layer">
            <ComputerLogo />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "chemistry":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag">
          <img src={chemistry} alt={props.subject} style={{ width: "80px" }} />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "biology":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag">
          <img src={biology} alt="logo" style={{ width: "80px" }} />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
    case "social science":
      return (
        <div
          style={{ width: "100px" }}
          onClick={() => props.selectSubject(props)}
          className="subjectTagContainer"
        >
          <div className="subjectTag layer ss">
            <SocialScience />
          </div>
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
      case "english":
        return (
          <div
            style={{ width: "100px" }}
            onClick={() => props.selectSubject(props)}
            className="subjectTagContainer"
          >
            <div className="subjectTag">
            <img src={english} alt="logo" style={{ width: "80px" }} />

            </div>
            <div className="subjectLabel">{props.subject}</div>
          </div>
        );
        case "environmental studies":
          return (
            <div
              style={{ width: "100px" }}
              onClick={() => props.selectSubject(props)}
              className="subjectTagContainer"
            >
              <div className="subjectTag">
              <img src={evs} alt="logo" style={{ width: "80px" }} />
  
              </div>
              <div className="subjectLabel">{props.subject}</div>
          </div>
        );
          case "evs":
            return (
              <div
                style={{ width: "100px" }}
                onClick={() => props.selectSubject(props)}
                className="subjectTagContainer"
              >
                <div className="subjectTag">
                <img src={evs} alt="logo" style={{ width: "80px" }} />

                </div>
                <div className="subjectLabel">{props.subject}</div>
              </div>
            );
            case "english grammar":
              return (
                <div
                  style={{ width: "100px" }}
                  onClick={() => props.selectSubject(props)}
                  className="subjectTagContainer"
                >
                  <div className="subjectTag">
                  <img src={englishGrammar} alt="logo" style={{ width: "80px" }} />
      
                  </div>
                  <div className="subjectLabel">{props.subject}</div>
                </div>
              );
              case "science":
                return (
                  <div
                    style={{ width: "100px" }}
                    onClick={() => props.selectSubject(props)}
                    className="subjectTagContainer"
                  >
                    <div className="subjectTag">
                    <img src={science} alt="logo" style={{ width: "80px" }} />
        
                    </div>
                    <div className="subjectLabel">{props.subject}</div>
                  </div>
                );
    default:
      return (
        <div className="subjectTag" onClick={() => props.selectSubject(props)}>
          {/* <Physics /> */}
          
          <img src={englishGrammar} alt="logo" style={{ width: "80px" }} />
          <div className="subjectLabel">{props.subject}</div>
        </div>
      );
  }
}
