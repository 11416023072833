/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */
// Previous sessions for replay are listed here.
// also known as completed sessions

import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import { CompletedLiveTuitionCard } from "../../../components/uiComponents/uiComponents";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import "../liveTuition.scss";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { getWindowDimensions, getxsValue } from "../../../shared/utils/utilityFunctions";


/** Actual Live LiveTuitionPrevious page function starts here **/
export function LiveTuitionPrevious() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    userName: "",
    standard: "",
    syllabus: "",
  });
  const [completedSessions, setCompletedSessions] = useState([
    {
      TransactionId: "",
      topic_name: "",
      teacher_name: "",
      subject_name: "",
      mst_session_course_id: "",
    },
  ]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 80);

    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (profileData.syllabus) getCompletedSessions();
    function getCompletedSessions() {
      setLoader(true);
      let apiData = {
        user: profileData.userName,
        standardId: profileData.standard,
        syllabusId: profileData.syllabus,
      };
      console.log(apiData);
      services.getCompletedSessions(apiData).then((res: any) => {
        setLoader(false);
        console.log(res);
        if (res.failed) console.log("failed");
        else {
          console.log(res.data.data);
          setCompletedSessions(res.data.data);
        }
      });
    }
  }, [profileData.syllabus]);
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  function navigateTo(path: string, props?: string) {
    navigate(path, { state: props });
  }
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [selected, setSelected] = React.useState([]);

  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  function showReplay(replayUrl: string) {
    console.log(replayUrl);
    navigateTo("/live-tuition/completed/playback", replayUrl);
  }

  return (
    loader ? <LoaderComponent /> :
    <Grid container style={{ width: "100vw" }} className="fade-in-animate">

      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"live-tuition"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent search={true} logo={false} profileData={profileData} />
        <span
          className="backToLiveTuition"
          onClick={() => {
            navigateTo("/live-tuition");
          }}
        >
          {"<"} Live Tuition
        </span>
        {/* scheduled sessions */}
        <div style={{ marginLeft: "3vw" }}>
          {/* completed sessions */}
          <Grid style={{ marginTop: "48px" }}>
            <span className="recommendedLabelText1">Revise the lessons</span>
            <br />
            <span className="recommendedLabelText2">
              Test your subject knowledge.
            </span>
          </Grid>
          <Grid container spacing={1} style={{ paddingLeft: "24px" }}>
            {completedSessions &&
              completedSessions.length > 0 ?
              completedSessions?.map((session: any) => (
                <CompletedLiveTuitionCard
                  itemId={session.ActivityID} // NOTE: itemId is required for track items
                  title={session.chapter_name}
                  key={session.content_uuid}
                  onClick={handleClick(session.ActivityID)}
                  selected={isItemSelected(session.ActivityID)}
                  button={"Play"}
                  teacherName={session.teacher_name}
                  showReplay={showReplay}
                  replayUrl={session.ReplayURL}
                  fromTime={session.from_time}
                  sessionDate={session.session_date}
                  subjectName={session.subject_name}
                  scienceSubject={session.session_subject_alias_name}
                />
              ))
              :(
                <div className="noSessions">
                  {" "}
                  <p
                    style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}
                  >
                    No sessions Found.
                  </p>
                </div>
              )}
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
