import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { animated, useSpring } from "@react-spring/web";
import {
  Avatar,
  CircularProgress,
  Grid,
  TextField,
  Checkbox,
} from "@mui/material";
import { ReactComponent as UsersAvatar } from "../../assets/icons/usersAvatar.svg";
// import CloseIcon from "@mui/icons-material/Close";
import { services } from "../../services/api_service";
import {
  getFromLocalStorage,
  setToLocalStorage,
  msToHMS,

} from "../../shared/utils/utilityFunctions";
import swal from "sweetalert";
import { useEffect, useState } from "react";
import { FadeProps } from "../../constants/interfaces";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import VideoLibrary from "@mui/icons-material/VideoLibrary";
import { constants } from "../../constants/constants";
import { LoaderWithMessageComponent } from "../../shared/loader/loader";
import AuthCode from "react-auth-code-input";

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(
  props,
  ref
) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: 3,
  boxShadow: 14,
  p: 4,
};

// export default function LiveTuitionMoreDetailsModal(props: {
//   setOpen: React.Dispatch<React.SetStateAction<boolean>>;
//   open: boolean;
//   handleOpen: () => void;
//   handleClose: () => void;
// }) {
//   return (
//     <div>
//       <Modal
//         aria-labelledby="spring-modal-title"
//         aria-describedby="spring-modal-description"
//         open={props.open}
//         onClose={props.handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={props.open}>
//           <Box sx={style}>
//             {/* <CloseIcon className="modalClosebutton" /> */}
//             <div className="recContents">
//               <Grid container>
//                 <Grid container item xs={9.5}>
//                   {/* should use background color for different subjects */}
//                   <div
//                     className="subLogoBackdrop"
//                     style={{ backgroundColor: "#FEF0DF" }}
//                   >
//                     {/* <BiologyLogo className="subjectLogo" style={{width:"20px"}} /> */}
//                     {/* <ChemistryLogo
//                 className="subjectLogo"
//                 style={{ width: "20px" }}
//               /> */}
//                   </div>
//                 </Grid>
//                 <Grid container item xs={2.5}>
//                   <div className="availableSeats">
//                     <UsersAvatar /> 4/8 Seats
//                   </div>
//                 </Grid>
//               </Grid>
//               <div className="courseName">
//                 Introduction - Orgin and Evolution of man
//               </div>

//               <Grid container>
//                 <Grid container item xs={6}>
//                   <div>
//                     <div className="courseDetailsLabel">
//                       Date :{" "}
//                       <span
//                         className="courseDetailsValue"
//                         style={{ fontWeight: "bold" }}
//                       >
//                         May 12
//                       </span>
//                     </div>
//                   </div>
//                 </Grid>
//                 <Grid container item xs={6}>
//                   <div>
//                     <div className="courseDetailsLabel">
//                       Time :{" "}
//                       <span
//                         className="courseDetailsValue"
//                         style={{ fontWeight: "bold" }}
//                       >
//                         4:00PM-4:30 PM
//                       </span>
//                     </div>
//                   </div>
//                 </Grid>
//               </Grid>
//               <Grid container>
//                 <Grid container item xs={6}>
//                   <div>
//                     <div className="courseDetailsLabel">
//                       Code :{" "}
//                       <span
//                         className="courseDetailsValue"
//                         style={{ fontWeight: "bold" }}
//                       >
//                         6REG1
//                       </span>
//                     </div>
//                   </div>
//                 </Grid>
//                 <Grid container item xs={6}>
//                   <div>
//                     <div className="courseDetailsLabel">
//                       Instructor :{" "}
//                       <span
//                         className="courseDetailsValue"
//                         style={{ fontWeight: "bold", color: "#1863b8" }}
//                       >
//                         Edward Esther
//                       </span>
//                     </div>
//                   </div>
//                 </Grid>
//               </Grid>
//               <Grid container className="cardFooter">
//                 <Grid container item xs={8.5}>
//                   {/* <div className="moreDetails" onClick={handleOpen}>
//                     More Details
//                   </div> */}
//                 </Grid>
//                 <Grid container item xs={3.5}>
//                   <div className="closeButton" onClick={props.handleClose}>
//                     close
//                   </div>
//                 </Grid>
//               </Grid>
//             </div>
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }

export function TeacherDetailsModal(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  // handleOpen: () => void;
  handleClose: () => void;
}) {
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            {/* <CloseIcon className="modalClosebutton" /> */}
            <div className="recContents">
              <Grid container>
                <Grid container item xs={3}>
                  {/* should use background color for different subjects */}
                  <Avatar sx={{ width: 86, height: 86 }} />
                </Grid>
                <Grid container item xs={9} className="">
                  <Grid item xs={12} className="reviewLabel teacher">
                    Soumya
              </Grid>
                  <Grid item xs={12} className="daysAgoText teacher">
                    M.Sc Biotechnology
                  </Grid>
                  <Grid item xs={12} className="daysAgoText">
                    1 year Experience
                  </Grid>
                  <Grid item xs={12}>
                    4.7 ?
                  </Grid>
                </Grid>
              </Grid>

              <div className="courseDetailsLabel" style={{ fontSize: "16px" }}>
                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                dolor in reprehenderit in voluptate veliLorem ipsum dolor sit
                amet, consectetur adipiscing elit, sed doeiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut eni ad minim
                veniam,
              </div>
              {/* <div className="reviewContainer">
                hello
              </div> */}
              <Grid className="reviewLabel" style={{ marginTop: 10 }}>
                Rating & Review
              </Grid>
              <Grid className="daysAgoText">Reviews guide you right!</Grid>
              <Grid container className="reviewContainer hideScrollBar">
                <Grid container className="reviewCards">
                  <Grid container item xs={2}>
                    <Avatar />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid item xs={12}>
                      Name
                    </Grid>
                    <Grid item xs={12} className="daysAgoText">
                      2 days ago
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} className="starContainer">
                    4.7 ?
                  </Grid>
                  <Grid item xs={12} className="daysAgoText p">
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure do sed doeiusmod tempor incididunt ut labore et dolore{" "}
                  </Grid>
                </Grid>

                <Grid container className="reviewCards">
                  <Grid container item xs={2}>
                    <Avatar />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid item xs={12}>
                      Name
                    </Grid>
                    <Grid item xs={12} className="daysAgoText">
                      2 days ago
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} className="starContainer">
                    4.7 ?
                  </Grid>
                  <Grid item xs={12} className="daysAgoText p">
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure do sed doeiusmod tempor incididunt ut labore et dolore{" "}
                  </Grid>
                </Grid>


                <Grid container className="reviewCards">
                  <Grid container item xs={2}>
                    <Avatar />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid item xs={12}>
                      Name
                    </Grid>
                    <Grid item xs={12} className="daysAgoText">
                      2 days ago
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} className="starContainer">
                    4.7 ?
                  </Grid>
                  <Grid item xs={12} className="daysAgoText p" >
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure do sed doeiusmod tempor incididunt ut labore et dolore{" "}
                  </Grid>
                </Grid>



                <Grid container className="reviewCards">
                  <Grid container item xs={2}>
                    <Avatar />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid item xs={12}>
                      Name
                    </Grid>
                    <Grid item xs={12} className="daysAgoText">
                      2 days ago
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} className="starContainer">
                    4.7 ?
                  </Grid>
                  <Grid item xs={12} className="daysAgoText p" >
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure do sed doeiusmod tempor incididunt ut labore et dolore{" "}
                  </Grid>
                </Grid>



                <Grid container className="reviewCards">
                  <Grid container item xs={2}>
                    <Avatar />
                  </Grid>
                  <Grid container item xs={7}>
                    <Grid item xs={12}>
                      Name
                  </Grid>
                    <Grid item xs={12} className="daysAgoText">
                      2 days ago
                    </Grid>
                  </Grid>
                  <Grid container item xs={2} className="starContainer">
                    4.7 ?
                  </Grid>
                  <Grid item xs={12} className="daysAgoText p" >
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                    irure do sed doeiusmod tempor incididunt ut labore et dolore{" "}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container className="cardFooter">
                <Grid container item xs={8.5}>
                  {/* <div className="moreDetails" onClick={handleOpen}>
                    More Details
                  </div> */}
                </Grid>
                <Grid container item xs={3.5}>
                  <div className="closeButton" onClick={props.handleClose}>
                    close
                  </div>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export function ConfirmAssessmentSubmit(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  review: any;
  submit: any;
}) {
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Grid container style={{textAlign:'center'}}>
              <Grid item xs={12} style={{ marginLeft: "37%" }}>
                <div className="backgrounQuestionMark">?</div>
              </Grid>
              <span className="messageLabel">
              Do you want to Submit 
              </span>
            </Grid>
            <Grid container className="cardFooter">
              <Grid container item xs={3}></Grid>
              <Grid container item xs={9}>
                <div
                  className="clearButton"
                  style={{ margin: 5 }}
                  onClick={props.review}
                >
                  Review
                </div>
                <div
                  className="nextButton"
                  style={{ marginLeft: 15, marginTop: 5 }}
                  onClick={props.submit}
                >
                  Submit
                </div>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export function ConfirmClassUpgrade(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  submit: any;
  classId: string;
}) {
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Grid container>
              <Grid item xs={12} style={{ marginLeft: "37%" }}>
                <div className="backgrounQuestionMark">?</div>
              </Grid>
              <span className="messageLabel">
                Are you sure that you want to Upgrade to class{" "}
                {props.classId + 1}
              </span>
            </Grid>
            <Grid container className="cardFooter">
              <Grid container item xs={3}></Grid>
              <Grid container item xs={9}>
                <div
                  className="clearButton"
                  style={{ margin: 5 }}
                  onClick={props.handleClose}
                >
                  Cancel
                </div>
                <div
                  className="nextButton"
                  style={{ marginLeft: 15, marginTop: 5 }}
                  onClick={props.submit}
                >
                  Yes
                </div>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
export function OtpValidate(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
  // submit: any;
  classId: string;
  handleOnChange:any;
  otpErr:any
}) {
  const childStyle={
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    borderRadius: 3,
    boxShadow: 14,
    p: 4,
  };
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={childStyle}>
            Verify OTP
          <AuthCode
        allowedCharacters="numeric"
        onChange={props.handleOnChange}
        inputClassName={props.otpErr ? "authCodeClassErr" : "authCodeClass"}
        // inputClassName= "authCodeClass"   
        length={constants.AUTH_CODE_LENGTH}
      />
      <Grid container item xs={9}>
                <div
                  className="clearButton"
                  style={{ margin: 5 }}
                  onClick={props.handleClose}
                >
                  Cancel
                </div>
               </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export function CouponModal(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleClose: any;
}) {
  interface State {
    cardNumber: string;
    activationCode: string;
  }
  const [values, setValues] = React.useState<State>({
    cardNumber: "",
    activationCode: "",
  });
  const [loader, setLoader] = useState(false);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex
      console.log(prop);
      if (
        prop === "cardNumber" &&
        event.target.value.length < 11 &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        return setValues({ ...values, [prop]: event.target.value });
      } else if (prop === "activationCode" && event.target.value.length < 17) {
        setValues({ ...values, [prop]: event.target.value });
      }
    };
  function activate() {
    if (loader) return;
    else {
      console.log(values);
      const profileData = getFromLocalStorage("profileData");
      console.log(profileData);
      let apiData = {
        CouponNo: parseInt(values.cardNumber),
        ScratchCode: values.activationCode,
        StudentId: profileData.userName,
        Program: 1,
        Syllabus: profileData.syllabus,
        Class: profileData.standard,
      };
      setLoader(true);
      services.activateScratchcard(apiData).then((res) => {
        setLoader(false);
        console.log(res);
        if (res.failed) {
          let failedMsg = res.data.msg
            ? res.data.msg.replace("'", "").replace("'", "")
            : res.ev.message
            ? res.ev.message
            : "Failed";
          console.log(failedMsg);
          swal({
            title: res.message,
            text: failedMsg,
            icon: "error",
          });
        } else {
          swal({
            title: res.message,
            text: res.data.msg,
            icon: "success",
          });
          props.handleClose();
        }
      });
    }
  }
  function cancel() {
    setValues({ cardNumber: "", activationCode: "" });
    props.handleClose();
  }
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        // onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <h3>Scratch Card Activation</h3>
            <div className="subLabel">
              Unlock new features, elevate your learning!
            </div>
            <TextField
              sx={{ width: "100%", marginTop: 2 }}
              id="outlined-basic"
              label="Card Number"
              variant="outlined"
              value={values.cardNumber}
              onChange={handleChange("cardNumber")}
            />
            <TextField
              sx={{ width: "100%", marginTop: 2 }}
              id="outlined-basic"
              label="Activation Code"
              variant="outlined"
              value={values.activationCode}
              onChange={handleChange("activationCode")}
            />
            <Grid container className="cardFooter">
              <Grid container item xs={3}></Grid>
              <Grid container item xs={9}>
                <div
                  className="clearButton"
                  style={{ margin: 5 }}
                  onClick={cancel}
                >
                  Cancel
                </div>
                <div
                  className="nextButton"
                  style={{ marginLeft: 15, marginTop: 5 }}
                  onClick={activate}
                >
                  {loader ? (
                    <CircularProgress size="1rem" style={{ color: "white" }} />
                  ) : (
                    "Activate"
                  )}
                </div>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export function ImageUploadModal(props: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  handleClose: () => void;
}) {
  // specify upload params and url for your files
  const getUploadParams = ({ meta }: any) => {
    return { url: "localhost:3000" };
  };
  const [previewUrl, setMetaData] = useState("");
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }: any, status: any) => {
    console.log(status, meta, file);
    setMetaData(meta.previewUrl);
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files: any) => {
    console.log(files);
    let profile = getFromLocalStorage("profileData");
    profile["profile_image"] = files[0].meta.previewUrl;
    setToLocalStorage("profileData", profile);
    console.log(files.map((f: any) => f.meta));
    props.handleClose();
  };
  useEffect(() => {
    console.log("meta fetched");
  }, [previewUrl]);
  return (
    <div style={{ maxHeight: "90vh" }}>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Grid container>
              <Dropzone
                // getUploadParams={getUploadParams}
                onChangeStatus={handleChangeStatus}
                onSubmit={handleSubmit}
                accept="image/*,audio/*,video/*"
                maxFiles={1}
              />
            </Grid>
            {/* {
              previewUrl&&
            <img src={previewUrl} style={{
              width:90
            }} alt="" />

            } */}
            <Grid container className="cardFooter">
              <Grid container item xs={9}></Grid>
              <Grid container item xs={3}>
                <div
                  className="clearButton"
                  style={{ margin: 5 }}
                  onClick={props.handleClose}
                >
                  Cancel
                </div>
                {/* <div
                  className="nextButton"
                  style={{ marginLeft: 15, marginTop: 5 }}
                  onClick={props.submit}
                >
                  Yes
                </div> */}
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
