import { Checkbox, Grid } from "@mui/material";
import { TextareaAutosize } from "@mui/base";

export function ReportsOWQ(props:any){
    
  function createMarkup(value: string, qn?:any) {
    if(qn){
          return {
      __html:qn+") <br />"+ value,
    };
    }else{
      return {
        __html: value,
      };
    }

  }
    return(
        <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q, props.question.qn)}
          item
        ></Grid>
        <Grid
          container
          item
          xs={7}
          sm={9}
          lg={10}
          style={{ height: "30vh", marginTop: "10vh" }}
        >
                 {/* <Grid item xs={12}>
       
          <Grid xs={10.4} className="question">
          <span> {props.selectedanswer} </span>
          </Grid>
        
          </Grid>  */}
          <Grid item xs={12}>
          {props.rightAnswer ? (
          <Grid xs={10.4} className="question">
            <span style={{color:"green", fontSize:"28px"}}>{props.selectedanswer} &nbsp; ✔</span>
          </Grid>
        ) :(
          <Grid xs={10.4} className="question">
            <span style={{color:'red',fontSize:"28px"}}>{props.selectedanswer} &nbsp; ✘</span><br />
            Correct Answer is : &nbsp;
            <span dangerouslySetInnerHTML={createMarkup(props.question.a)}></span>
          </Grid>
        
        )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "20vh" }}
      >
               <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
               <span
              className="nextButton"
              style={{padding:'11px'}}
              onClick={() => props.backToReports()}
            >
              Back to Reports
            </span>
        </Grid>
       
        <Grid container item xs={1.5}>
          {props.qnIndex > 1 ? (
            <span
              className="nextButton previous"
              onClick={() => props.changeQuestion(false)}
            >
              Previous
            </span>
          ) : (
            <span className="nextButton disabled">Previous</span>
          )}
        </Grid>
        <Grid container item xs={2}>
          {props.qnIndex + 1 < props.total ? (
            <span
              className="nextButton"
              onClick={() => props.changeQuestion(true)}
            >
              Next
            </span>
          ) : (
            <span className="nextButton disabled">Next</span>
          )}
        </Grid>
      </Grid>
    </>
    )
}