import { Checkbox, Grid } from "@mui/material";
import "./assessMeComponents.scss";
import { useEffect, useState } from "react";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";

export default function FIBComponent(props: {
  markAnswer: any;
  question: any;
  nextQuestion: any;
  prevQuestion: any;
  answeredData: any;
  currQnindex: any;
  qnStartTime: any;
}) {
  const [questionWithBlanks, setQuestion] = useState(props.question.q);
  var [answer, setAnswer] = useState("");
  var [marked, setMarkForReview] = useState(false);
  var count = props.question.q.match(/\[SPACE]/g).length;

  var newQuestion = props.question.q;
  function show() {
    var inputValue = [];

    // IMPORTANT AS THIS LOGIC IS REQUIRED TO EXTRACT VALUE FROM HTML INPUT
    for (let i = 0; i < count; i++) {
      inputValue[i] = (
        document.getElementById("fibInput" + i) as HTMLInputElement
      ).value;
    }

    console.log(inputValue);
  }
  function createMarkup(value: string) {
    // replacing [SPACE] with custom html input
    for (let i = 0; i < count; i++) {
      newQuestion = newQuestion.replace(
        "[SPACE]",
        '&nbsp;&nbsp;<input type="text" id="fibInput' +
          i +
          '" style="outline: 0;border-width: 0 0 2px"/>&nbsp;&nbsp;'
      );
    }
    return {
      __html: newQuestion,
    };
  }

  useEffect(() => {
    console.log(props.answeredData);
    let currQn = props.answeredData[props.currQnindex];
    setMarkForReview(currQn.marked?true : false);

    if (currQn && currQn.answer && currQn.answer.selectedanswer !== undefined) {
      let selectedAns = currQn.answer.selectedanswer
      for(let i=0; i<selectedAns.length;i++){
        let one:any = selectedAns[i];
        (document.getElementById("fibInput"+i) as HTMLInputElement).value = one
        ? one
        : "";
      }
     
      setAnswer(currQn.answer.selectedanswer);
    } else {
      console.log("answered alla");
      setAnswer("");
    }
  }, [props.question]);

  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(event.target.value, id);
    setMarkForReview(marked => !marked)
    console.log(marked)
  };
  function changeQuestion(param: boolean) {
    // param true = next question
    // param false = previous question
    if (param) {
      let packageId = getFromLocalStorage("packageId");
      let arr: any[] = [];
      for (let i = 0; i < count; i++) {
        arr[i] = (
          document.getElementById("fibInput" + i) as HTMLInputElement
        ).value;
      }
      let apiData = {
        answer: {
          question_id: props.question.content_uuid,
          totaltime: 0,
          package_id: packageId,
          question_type: "FillInTheBlanks",
          skipped: !arr[0] ? true : false,
          Blanks: arr[0] ? arr : "",
        },
      };
      console.log(apiData);
      props.nextQuestion(apiData, marked);
    } else {
      props.prevQuestion();
    }
  }
  function clear() {
    for (let i = 0; i < count; i++) {
      (document.getElementById("fibInput"+i) as HTMLInputElement).value = "";
    }
    
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(questionWithBlanks)}
        ></Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <Checkbox
            onChange={(e) => {
              markForReview(e, props.question);
            }}
            checked={marked}
            defaultChecked={marked}
          />
          <span className="questionNo"> Mark for review</span>
        </Grid>
        <Grid container item xs={2.5}>
          <span className="clearButton" onClick={() => clear()}>
            Clear
          </span>
        </Grid>
        <Grid container item xs={1.5}>
          <span
            className="nextButton previous"
            onClick={() => changeQuestion(false)}
          >
            Previous
          </span>
        </Grid>
        <Grid container item xs={2}>
          <span className="nextButton" onClick={() => changeQuestion(true)}>
            Next
          </span>
        </Grid>
      </Grid>
    </>
  );
}
