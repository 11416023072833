/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Reports page.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Breadcrumbs, Grid, Typography } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

import { services } from "../../services/api_service";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import { formatSecTommss, getDate } from "../../shared/utils/utilityFunctions";
import LoaderComponent from "../../shared/loader/loader";
// import ResponsiveList from "../../shared/responsiveList/responsiveListComponent";
import { GridColDef } from "@mui/x-data-grid";

const getxsValue = (isfor: string, windowFlag: boolean) => {
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Reprts page function starts here */
export function ReportPage() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
  });

  const [report, setReport] = useState([{}]);
  const [topicData, setTopicData] = useState([{ QID: "" }]);

  const [loader, setLoader] = useState(true);
  const [stage, setStage] = useState("default");

  useEffect(() => {
    validateLoggedIn();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  useEffect(() => {
    function fetchReport() {
      setLoader(true);
      services.getReport(profileData.userName).then((res) => {
        setLoader(false);
        console.log(res);
        if (res.failed) return;
        else {
          console.log(res.data.data);
          let rawRep = res.data.data
          rawRep.forEach(function (one:any, index:number) {
          one.id=index+1
          one.date = getDate(one.CreatedDate) 
          one.time=formatSecTommss(one.TimeConsumed)
        });
        console.log(rawRep)
          setReport(rawRep);
        }
      });
    }
    if (profileData.userName) fetchReport();
  }, [profileData.userName]);

  function moreDetails(one: any) {
    console.log(one);
    setTopicData(one);
    setStage("overallPerformance");
  }

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => setStage("default")}
    >
      Assess Me Report
    </Link>,
    <Typography key="3" color="text.primary">
      Overall Report
    </Typography>,
  ];

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'No', width: 90 },
    {
        field: 'date',
        headerName: 'Date ',
        width: 150,
    },
    {
        field: 'SubjectName',
        headerName: 'Subject',
        width: 120,
    },
    {
        field: 'ChapterName',
        headerName: 'Chapter',
        width: 390,
    }, {
        field: 'Score',
        headerName: 'Score',
        type: 'number',
        width: 190,
    }, {
        field: 'time',
        headerName: 'Time Consumed',
        type: 'number',
        width: 210,
    }
    // , {
    //   field: 'Actions',
    //   headerName: 'Actions',
    //   width: 110,
    //   renderCell: (params) => {
    //     const handleMoreDetailsClick = () => {
    //       // Function to be triggered when the "More details" button is clicked
    //       moreDetails(params.row); // Assuming your `moreDetails` function takes the row data as an argument
    //     };
  
    //     return (
    //       <button onClick={handleMoreDetailsClick}>
    //         More details
    //       </button>
    //     );
    //   },
    // },

];


  return (
    <Grid container style={{ width: "100vw" }} className="fade-in-animate">
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag)}>
        <Navbar path={"reports"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag)}>
        <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        />
        {stage === 'overallPerformance' && (
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            style={{ marginTop: "28px", marginLeft: "25px" }}
          >
            {breadcrumbs}
          </Breadcrumbs>
        )}
        <Grid
          container
          spacing={2}
          item
          xs={12}
          style={{ marginTop: "20px", minWidth:'100vh', overflowY:'auto' }}
        >
          {stage === "default" && (
            // <div>hello</div>
              // report && <ResponsiveList rows={report} columns={columns}/>
            
            
            <>
              <Grid container item>
                <Grid container item className="hedderRow">
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Date
                    </span>
                  </Grid>
                  <Grid container item xs={2}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Subject
                    </span>
                  </Grid>
                  <Grid container item xs={4}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Chapter
                    </span>
                  </Grid>
                  <Grid container item xs={1.3}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Score
                    </span>
                  </Grid>
                  <Grid container item xs={1.3}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Time
                    </span>
                  </Grid>
                  <Grid container item xs={1}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Actions
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {report && report.length > 0 ? (
                report.map((one: any) => (
                  <Grid container item>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one.CreatedDate ? getDate(one.CreatedDate) : "Nil"}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one.SubjectName}
                      </span>
                    </Grid>
                    <Grid container item xs={4}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one.ChapterName}
                      </span>
                    </Grid>
                    <Grid container item xs={1.3}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {one.Score}
                      </span>
                    </Grid>
                    <Grid container item xs={1}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {formatSecTommss(one.TimeConsumed)}
                      </span>
                    </Grid>
                    <Grid container item xs={2}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        <div
                          className="moreDetails"
                          onClick={() => moreDetails(one.TopicReport)}
                        >
                          More Details
                        </div>
                      </span>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <div style={{ padding: "25px", fontSize: "30px" }}>
                  No Reports Found
                </div>
              )}
            </>
          )}
          {stage === "overallPerformance" && (
            <>
              <Grid container item>
                <Grid container item className="hedderRow">
                  <Grid container item xs={4.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Topic
                    </span>
                  </Grid>
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Questions
                    </span>
                  </Grid>
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Correct
                    </span>
                  </Grid>
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Incorrect
                    </span>
                  </Grid>
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Not Attempted
                    </span>
                  </Grid>
                  <Grid container item xs={1.5}>
                    <span
                      style={{
                        color: "#0E2744",
                        opacity: 0.8,
                        fontWeight: "bold",
                        fontSize: 16,
                      }}
                    >
                      Percentage
                    </span>
                  </Grid>
                </Grid>
              </Grid>
              {topicData &&
                topicData.map((topic: any) => (
                  <Grid container item>
                    <Grid container item xs={4.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.TopicName}
                      </span>
                    </Grid>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.Total}
                      </span>
                    </Grid>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.RightAnswer}
                      </span>
                    </Grid>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.WrongAnswer}
                      </span>
                    </Grid>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.Skipped}
                      </span>
                    </Grid>
                    <Grid container item xs={1.5}>
                      <span
                        style={{
                          color: "#0E2744",
                          opacity: 0.8,
                          fontSize: 16,
                        }}
                      >
                        {topic.Percentage}
                      </span>
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
