import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { Badge, Icon, MenuItem, OutlinedInput, TextField } from "@mui/material";
import { COUNTRY_CODES_LIST } from "../../../constants/countryCodes";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { ReactComponent as FabRightArrow } from "../../../assets/icons/fabRightArrow.svg";
import { useNavigate } from "react-router-dom";
import { services } from "../../../services/api_service";
import swal from "sweetalert";
import { messageUtil } from "../../../shared/utils/messages";
import LoaderComponent from "../../../shared/loader/loader";
import EditIcon from "@mui/icons-material/Edit";
import { ImageUploadModal } from "../../../components/uiComponents/modals";
import { getInitials } from "../../../shared/utils/utilityFunctions";

interface ProfileState {
  country: string;
  pCountry: string;
  phone: string;
  altPhone: string;
  name: string;
  email: string;
  parentsEmail: string;
  syllabus: string;
  city: string;
  standard: string;
  syllabusName: string;
}
export function SettingsProfilePage() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({
    name: "",
    altMobileNo: "",
    city: "",
    syllabus: "",
    altEmailId: "",
    syllabusName: "",
    userName: "",
    className: "",
    standard: "",
    profile_image: "",
  });
  useEffect(() => {
    validateLoggedIn();
  }, []);
  const [userData, setUserData] = useState({
    email: "",
    mobile: "",
  });
  const [loader, setLoader] = useState(false);

  const [noEmail, setNoEmail] = useState(false);
  useEffect(() => {
    function setProfileDataToValues() {
      setValues({
        country: "+965",
        pCountry: "+965",
        phone: userData.mobile,
        altPhone: profileData.altMobileNo,
        name: profileData.name,
        email: userData.email,
        parentsEmail: profileData.altEmailId,
        syllabus: profileData.syllabus,
        city: profileData.city,
        standard: "class" + profileData.standard,
        syllabusName: profileData.syllabusName,
      });
    }
    if (!userData.email) {
      setNoEmail(true);
    } else {
      setNoEmail(false);
    }
    setProfileDataToValues();
  }, [profileData, userData]);

  const [edit, setEdit] = useState(false);

  // values should be filled initially
  const [values, setValues] = React.useState<ProfileState>({
    country: "+965",
    pCountry: "+965",
    phone: userData.mobile,
    altPhone: profileData.altMobileNo,
    name: profileData.name,
    email: userData.email,
    parentsEmail: profileData.altEmailId,
    syllabusName: profileData.syllabusName,
    syllabus: profileData.syllabus,
    city: profileData.city,
    standard: profileData.standard,
  });
  console.log(profileData);

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");
    if (typeof val !== "undefined" && val !== null) {
      var userData = localStorage.getItem("userData");
      setProfileData(JSON.parse(val));
      if (typeof userData !== "undefined" && userData !== null)
        setUserData(JSON.parse(userData));
    } else {
      navigateTo("/login");
    }
  };

  const handleChange =
    (prop: keyof ProfileState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const re = /^[0-9\b]+$/;

      // if value is not blank, then test the regex
      if (
        prop === "phone" &&
        (event.target.value === "" || re.test(event.target.value))
      ) {
        return setValues({ ...values, [prop]: event.target.value });
      } else if (prop !== "phone") {
        setValues({ ...values, [prop]: event.target.value });
        console.log(prop);
      }
    };

  function navigateTo(path: string) {
    navigate(path);
  }

  function updateProfile() {
    console.log(profileData);
    if (!/^[a-zA-Z ]+$/.test(values.name)) {
      swal({
        title: "Enter a valid name",
        icon: "warning",
      });
      return;
    }
    if (values.parentsEmail)
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          values.parentsEmail
        )
      ) {
        swal({
          title: "Enter a valid Parents Email",
          icon: "warning",
        });
        return;
      }
    setLoader(true);

    var apiData = {
      mobile: values.phone,
      altMobileCode: values.pCountry,
      altMobileNo: values.altPhone,
      userName: profileData.userName,
      name: values.name,
      altEmailId: values.parentsEmail,
      city: values.city,
      standard: profileData.standard,
      className: profileData.className,
      syllabus: profileData.syllabus,
      syllabusName: profileData.syllabusName,
      programtype: 1,
      programtype_t: "school",
      updatedOn: new Date(),
      finalExam: "",
      // email:values.email
    };
    console.log(apiData);
    services.updateProfile(apiData).then((res) => {
      if (res.failed) {
        console.log(res.message);
        setLoader(false);
      } else {
        console.log(res);

        services.getLearnerProfile(userData.mobile).then((res) => {
          setLoader(false);
          console.log(res);
          if (res.failed) {
            console.log(res, "failed");
          } else {
            setUserData(res.data.data);
            localStorage.setItem("userData", JSON.stringify(res.data.data));
            let profileArr = res.data.data.profile;
            for (let p = 0; p < profileArr.length; p++) {
              let one = profileArr[p];
              if (one.userName === profileData.userName) {
                setProfileData(one);
                localStorage.setItem("profileData", JSON.stringify(one));
              }
            }
            swal({
              title: messageUtil.PROFILE_UPDATE_SUCCESFULL,
              icon: "success",
            });
            navigateTo("/dashboard");
          }
        });
      }
    });
  }
  const [open, setOpen] = useState(false);
  function openPicUpload() {
    setOpen(true);
  }
  const handleClose = () => {
    console.log(false);
    setOpen(false);
  };
  return (
    <div className="SettingsProfileContainer fade-in-animate">
      <ImageUploadModal
        open={open}
        setOpen={setOpen}
        handleClose={handleClose}
      />
      {loader && <LoaderComponent />}
      <TopBarComponent search={false} logo={false} profileData={profileData} />
      {/* <div>{"<"} Profile</div>
      <div className="settingsContainer bgRed">
        <div className="subSettingsContainer">My profile</div>
      </div> */}
      <div className="backToSettings" onClick={() => navigateTo("/dashboard")}>
        <FabRightArrow className="invertFavArrow" />
        <span className="backToSettingslabel">Dashboard</span>
      </div>
      <div className="settingsContainer subContainer pd-t-50 hideScrollBar">
        {edit === true ? (
          <Grid
            className="subSettingsContainer fade-in-animate"
            container
            item
            xs={12}
            sm={8}
            md={4}
          >
            <div>
              <span className="settingMenuContainerHeader">Profile</span>
              <br />
              <span className="listLabel">
                Hi {profileData.name}! Here’s your student profile
              </span>
            </div>
            {/* <div className="settingsCardContaianer subSettingsOptionsContainer"> */}
            <Grid
              className="settingsCardContaianer subSettingsOptionsContainer"
              container
              item
              xs={12}
              sm={12}
              md={12}
            >
              {/* <div className="settingsProfileImageContainer"></div> */}
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={<EditIcon />}
                onClick={openPicUpload}
              >
                <Avatar
                  alt="Travis Howard"
                  sx={{ width: 90, height: 90 }}
                  src={profileData.profile_image}
                >
                    {getInitials(profileData.name)}
                </Avatar>
              </Badge>
              {/* <Avatar sx={{ width: 90, height: 90 }}>H</Avatar> */}
              <span className="profileName">{profileData.name}</span>
              <span className="profileStandard">
                Class &nbsp;{profileData.standard}
              </span>
              <div className="inputContainer">
                <TextField
                  className="nameInput"
                  id="outlined-adornment-currency"
                  label="Name"
                  value={values.name}
                  onChange={handleChange("name")}
                  error={values.name === ""}
                ></TextField>
              </div>
              <div className="inputContainer">
                <TextField
                  className="nameInput"
                  type="email"
                  id="outlined-adornment-currency"
                  label="Primary  Email"
                  value={values.email}
                  disabled={!noEmail}
                  onChange={handleChange("email")}
                ></TextField>
              </div>
              <div className="inputContainer">
                <TextField
                  className="nameInput"
                  type="email"
                  id="outlined-adornment-currency"
                  label="Parent’s  Email"
                  value={values.parentsEmail}
                  // disabled={props.values.email ? true : false}
                  onChange={handleChange("parentsEmail")}
                ></TextField>
              </div>

              <div className="inputContainer">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  // onChange={handleChange("phone")}
                  style={{ paddingLeft: "0px", width: "300px" }}
                  value={"Primary No " +values.phone}

                  // placeholder={"Primary No " + values.phone}
                  disabled
                  startAdornment={
                    <TextField
                      id="outlined-select-currency"
                      select
                      value={values.country}
                      onChange={handleChange("country")}
                      placeholder="hai"
                      disabled
                      // onClick={togglePhoneCode}
                      className="phonePrefix"
                    >
                      {COUNTRY_CODES_LIST.map((option) => (
                        <MenuItem key={option.name} value={option.dial_code}>
                          {option.dial_code}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  className="phoneNumberClass"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </div>
              <div className="inputContainer">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={values.altPhone}
                  onChange={handleChange("altPhone")}
                  style={{ paddingLeft: "0px", width: "300px" }}
                  startAdornment={
                    <TextField
                      id="outlined-select-currency"
                      select
                      value={values.pCountry}
                      onChange={handleChange("pCountry")}
                      placeholder="hai"
                      // onClick={togglePhoneCode}
                      className="phonePrefix"
                    >
                      {COUNTRY_CODES_LIST.map((option) => (
                        <MenuItem key={option.name} value={option.dial_code}>
                          {option.dial_code}
                        </MenuItem>
                      ))}
                    </TextField>
                  }
                  className="phoneNumberClass"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </div>
              {/* there is a bug that for old users(Data migrated from old db has no syllabus name) */}
              <div className="inputContainer">
                <TextField
                  disabled
                  className="nameInput"
                  type="email"
                  id="outlined-adornment-currency"
                  label="Syllabus"
                  value={values.syllabusName}
                ></TextField>
              </div>
              <div className="inputContainer">
                <TextField
                  className="nameInput"
                  id="outlined-adornment-currency"
                  label="City"
                  value={values.city}
                  onChange={handleChange("city")}
                ></TextField>
              </div>
              <div className="settingsSubFooter">
                <Button
                  variant="text"
                  className="settingsCancelButton"
                  onClick={() => navigateTo("/settings")}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="settingsSaveButton"
                  onClick={updateProfile}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Grid>
        ) : (
          <Grid
            className="subSettingsContainer"
            container
            item
            xs={12}
            sm={8}
            md={4}
          >
            <div>
              <span className="settingMenuContainerHeader">Profile</span>
              <br />
              <span className="listLabel">
                Hi {profileData?.name?.split(" ")[0]}! Here’s your student
                profile
              </span>
            </div>
            <Grid container className="userBasicInfoContainer" item xs={12}>
              <Grid item xs={3}>
                <Avatar
                  sx={{ width: 60, height: 60 }}
                  src={profileData.profile_image}
                >
                    {getInitials(profileData.name)}
                </Avatar>
              </Grid>
              <Grid item xs={7}>
                <div>
                  <span className="settingMenuContainerHeader">
                    {profileData.name}
                  </span>
                  <br />
                  <span className="listLabel">
                    class {profileData.standard}
                  </span>
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className="editBtn" onClick={() => setEdit(true)}>
                  Edit
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              className="userBasicInfoContainer mt-28"
              item
              xs={12}
            >
              <Grid item xs={6}>
                <div>
                  <span className="listLabel">Email</span>

                  <br />
                  <span className="settingMenuContainerHeader">
                    {profileData.altEmailId}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <span className="listLabel">City</span>

                  <br />
                  <span className="settingMenuContainerHeader">
                    {profileData.city ? profileData.city : "Nil"}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "25px" }}>
                <div>
                  <span className="listLabel">Parents Number</span>

                  <br />
                  <span className="settingMenuContainerHeader">
                    {userData.mobile}
                  </span>
                </div>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "25px" }}>
                <div>
                  <span className="listLabel">Alternative Number</span>

                  <br />
                  <span className="settingMenuContainerHeader">
                    {values.altPhone ? values.altPhone : "Nil"}
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
      {/* </div> */}
    </div>
  );
}
