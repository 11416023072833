/* 
* @author Rahul krishna.
* @date 01/11/2022.
* @version 1.0.0

=> Sidebar navigation components are present here.
*/

import "./navIconComponents.scss";

export function NavIconProvider(props: {
  item: any;
  window: boolean;
  selected: string;
}) {
  switch (props.item.icon) {
    case "dashboard":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.75 15.5H7.375V0.5H1.75C1.41667 0.5 1.125 0.625 0.875 0.875C0.625 1.125 0.5 1.41667 0.5 1.75V14.25C0.5 14.5833 0.625 14.875 0.875 15.125C1.125 15.375 1.41667 15.5 1.75 15.5ZM8.625 15.5H14.25C14.5833 15.5 14.875 15.375 15.125 15.125C15.375 14.875 15.5 14.5833 15.5 14.25V7.97917H8.625V15.5ZM8.625 6.72917H15.5V1.75C15.5 1.41667 15.375 1.125 15.125 0.875C14.875 0.625 14.5833 0.5 14.25 0.5H8.625V6.72917Z"
              fill="#0E2744"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "live-tuition":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.98047 10.7083L12.543 7.16667L6.98047 3.625V10.7083ZM5.8763 15.5V13.8333H1.91797C1.58464 13.8333 1.29297 13.7083 1.04297 13.4583C0.792969 13.2083 0.667969 12.9167 0.667969 12.5833V1.75C0.667969 1.41667 0.792969 1.125 1.04297 0.875C1.29297 0.625 1.58464 0.5 1.91797 0.5H16.0846C16.418 0.5 16.7096 0.625 16.9596 0.875C17.2096 1.125 17.3346 1.41667 17.3346 1.75V12.5833C17.3346 12.9167 17.2096 13.2083 16.9596 13.4583C16.7096 13.7083 16.418 13.8333 16.0846 13.8333H12.1263V15.5H5.8763Z"
              fill="#0E2744"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "learn-on":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="16"
            height="19"
            viewBox="0 0 16 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 18.7487C6.91667 17.7904 5.73264 17.0543 4.44792 16.5404C3.16319 16.0265 1.84722 15.7695 0.5 15.7695V6.83203C1.80556 6.83203 3.10069 7.13064 4.38542 7.72786C5.67014 8.32509 6.875 9.15842 8 10.2279C9.125 9.15842 10.3299 8.32509 11.6146 7.72786C12.8993 7.13064 14.1944 6.83203 15.5 6.83203V15.7695C14.1528 15.7695 12.8368 16.0265 11.5521 16.5404C10.2674 17.0543 9.08333 17.7904 8 18.7487ZM8.0625 7.2487C7.15972 7.2487 6.3993 6.93967 5.78125 6.32161C5.16319 5.70356 4.85417 4.94314 4.85417 4.04036C4.85417 3.13759 5.16319 2.37717 5.78125 1.75911C6.3993 1.14106 7.15972 0.832031 8.0625 0.832031C8.96528 0.832031 9.72569 1.14106 10.3438 1.75911C10.9618 2.37717 11.2708 3.13759 11.2708 4.04036C11.2708 4.94314 10.9618 5.70356 10.3438 6.32161C9.72569 6.93967 8.96528 7.2487 8.0625 7.2487Z"
              fill="#0E2744"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "assess-me":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="20"
            height="16"
            viewBox="0 0 20 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.9154 12.1042V6.22917L9.97787 10.5L0.832031 5.5L9.97787 0.5L19.1654 5.5V12.1042H17.9154ZM9.97787 15.5L3.9362 12.1875V7.8125L9.97787 11.125L16.0195 7.8125V12.1875L9.97787 15.5Z"
              fill="#0E2744"
            />
          </svg>
          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 6 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "reports":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.77083 12.1875H9.5V10.9375H3.77083V12.1875ZM3.77083 8.625H12.2292V7.375H3.77083V8.625ZM3.77083 5.0625H12.2292V3.8125H3.77083V5.0625ZM1.75 15.5C1.41667 15.5 1.125 15.375 0.875 15.125C0.625 14.875 0.5 14.5833 0.5 14.25V1.75C0.5 1.41667 0.625 1.125 0.875 0.875C1.125 0.625 1.41667 0.5 1.75 0.5H14.25C14.5833 0.5 14.875 0.625 15.125 0.875C15.375 1.125 15.5 1.41667 15.5 1.75V14.25C15.5 14.5833 15.375 14.875 15.125 15.125C14.875 15.375 14.5833 15.5 14.25 15.5H1.75Z"
              fill="#0E2744"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "settings":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.08464 17.3346L6.66797 14.7096C6.40408 14.6124 6.1263 14.4805 5.83464 14.3138C5.54297 14.1471 5.28602 13.9735 5.0638 13.793L2.60547 14.918L0.667969 11.5013L2.91797 9.85547C2.89019 9.73047 2.87283 9.58811 2.86589 9.42838C2.85894 9.26866 2.85547 9.1263 2.85547 9.0013C2.85547 8.8763 2.85894 8.73394 2.86589 8.57422C2.87283 8.4145 2.89019 8.27214 2.91797 8.14714L0.667969 6.5013L2.60547 3.08464L5.0638 4.20964C5.28602 4.02908 5.54297 3.85547 5.83464 3.6888C6.1263 3.52214 6.40408 3.39714 6.66797 3.3138L7.08464 0.667969H10.918L11.3346 3.29297C11.5985 3.39019 11.8798 3.51866 12.1784 3.67839C12.477 3.83811 12.7305 4.01519 12.9388 4.20964L15.3971 3.08464L17.3346 6.5013L15.0846 8.10547C15.1124 8.24436 15.1298 8.39366 15.1367 8.55338C15.1437 8.71311 15.1471 8.86241 15.1471 9.0013C15.1471 9.14019 15.1437 9.28602 15.1367 9.4388C15.1298 9.59158 15.1124 9.73741 15.0846 9.8763L17.3346 11.5013L15.3971 14.918L12.9388 13.793C12.7166 13.9735 12.4631 14.1506 12.1784 14.3242C11.8937 14.4978 11.6124 14.6263 11.3346 14.7096L10.918 17.3346H7.08464ZM9.0013 11.7096C9.7513 11.7096 10.3902 11.4457 10.918 10.918C11.4457 10.3902 11.7096 9.7513 11.7096 9.0013C11.7096 8.2513 11.4457 7.61241 10.918 7.08464C10.3902 6.55686 9.7513 6.29297 9.0013 6.29297C8.2513 6.29297 7.61241 6.55686 7.08464 7.08464C6.55686 7.61241 6.29297 8.2513 6.29297 9.0013C6.29297 9.7513 6.55686 10.3902 7.08464 10.918C7.61241 11.4457 8.2513 11.7096 9.0013 11.7096Z"
              fill="#0E2744"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
    case "recorded-videos":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="18"
            height="13"
            viewBox="0 0 18 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="15" height="14" rx="2" fill="#535353" />
            <path d="M13 7L18 0.874999V13.125L13 7Z" fill="#535353" />
            <rect x="18" y="1" width="3" height="12" fill="#535353" />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );

    case "worksheets":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="20"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="1" width="16" height="19" rx="1" fill="#535353" />
            <line
              y1="-0.5"
              x2="6.34993"
              y2="-0.5"
              transform="matrix(0.790397 0.612594 -0.587372 0.809317 11.2927 1)"
              stroke="white"
            />
            <rect x="3" y="4" width="11" height="1" rx="0.5" fill="white" />
            <rect x="3" y="6" width="11" height="1" rx="0.5" fill="white" />
            <rect x="3" y="9" width="11" height="1" rx="0.5" fill="white" />
            <rect
              width="21.6897"
              height="2.55173"
              transform="matrix(0.6954 -0.718623 0.6954 0.718623 3.76422 17.8834)"
              fill="black"
            />
            <path
              d="M3.72696 18.17L5.23665 19.7856L3.12793 20.3289L3.72696 18.17Z"
              fill="black"
            />
            <circle cx="20" cy="3" r="1" fill="black" />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );

    case "mocktest":
      return (
        <div
          className={`${
            props.selected === props.item.icon ? "" : "iconDisabled"
          }`}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 68 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="15" width="68" height="41" rx="1" fill="#535353" />
            <rect x="32" y="54" width="4" height="9" fill="#535353" />
            <rect x="3" y="17" width="61" height="32" fill="white" />
            <circle cx="34" cy="52" r="1" fill="white" />
            <rect x="21" y="63" width="25" height="2" rx="1" fill="#535353" />
            <rect x="16" y="2" width="37" height="43" rx="1" fill="#535353" />
            <path
              d="M27.4148 12H26.3068L29.5114 3.27273H30.6023L33.8068 12H32.6989L30.0909 4.65341H30.0227L27.4148 12ZM27.8239 8.59091H32.2898V9.52841H27.8239V8.59091Z"
              fill="white"
            />
            <ellipse cx="21.5" cy="8" rx="3.5" ry="3" fill="white" />
            <ellipse cx="21.5" cy="19" rx="3.5" ry="3" fill="white" />
            <ellipse cx="21.5" cy="31" rx="3.5" ry="3" fill="white" />
            <path
              d="M34.0795 29H33.0227C32.9602 28.696 32.8509 28.429 32.6946 28.1989C32.5412 27.9688 32.3537 27.7756 32.1321 27.6193C31.9134 27.4602 31.6705 27.3409 31.4034 27.2614C31.1364 27.1818 30.858 27.142 30.5682 27.142C30.0398 27.142 29.5611 27.2756 29.1321 27.5426C28.706 27.8097 28.3665 28.2031 28.1136 28.723C27.8636 29.2429 27.7386 29.8807 27.7386 30.6364C27.7386 31.392 27.8636 32.0298 28.1136 32.5497C28.3665 33.0696 28.706 33.4631 29.1321 33.7301C29.5611 33.9972 30.0398 34.1307 30.5682 34.1307C30.858 34.1307 31.1364 34.0909 31.4034 34.0114C31.6705 33.9318 31.9134 33.8139 32.1321 33.6577C32.3537 33.4986 32.5412 33.304 32.6946 33.0739C32.8509 32.8409 32.9602 32.5739 33.0227 32.2727H34.0795C34 32.7187 33.8551 33.1179 33.6449 33.4702C33.4347 33.8224 33.1733 34.1222 32.8608 34.3693C32.5483 34.6136 32.1974 34.7997 31.8082 34.9276C31.4219 35.0554 31.0085 35.1193 30.5682 35.1193C29.8239 35.1193 29.1619 34.9375 28.5824 34.5739C28.0028 34.2102 27.5469 33.6932 27.2145 33.0227C26.8821 32.3523 26.7159 31.5568 26.7159 30.6364C26.7159 29.7159 26.8821 28.9205 27.2145 28.25C27.5469 27.5795 28.0028 27.0625 28.5824 26.6989C29.1619 26.3352 29.8239 26.1534 30.5682 26.1534C31.0085 26.1534 31.4219 26.2173 31.8082 26.3452C32.1974 26.473 32.5483 26.6605 32.8608 26.9077C33.1733 27.152 33.4347 27.4503 33.6449 27.8026C33.8551 28.152 34 28.5511 34.0795 29Z"
              fill="white"
            />
            <path
              d="M27.0568 23V14.2727H30.108C30.7159 14.2727 31.2173 14.3778 31.6122 14.5881C32.0071 14.7955 32.3011 15.0753 32.4943 15.4276C32.6875 15.777 32.7841 16.1648 32.7841 16.5909C32.7841 16.9659 32.7173 17.2756 32.5838 17.5199C32.4531 17.7642 32.2798 17.9574 32.0639 18.0994C31.8509 18.2415 31.6193 18.3466 31.3693 18.4148V18.5C31.6364 18.517 31.9048 18.6108 32.1747 18.7812C32.4446 18.9517 32.6705 19.196 32.8523 19.5142C33.0341 19.8324 33.125 20.2216 33.125 20.6818C33.125 21.1193 33.0256 21.5128 32.8267 21.8622C32.6278 22.2116 32.3139 22.4886 31.8849 22.6932C31.456 22.8977 30.8977 23 30.2102 23H27.0568ZM28.1136 22.0625H30.2102C30.9006 22.0625 31.3906 21.929 31.6804 21.6619C31.973 21.392 32.1193 21.0653 32.1193 20.6818C32.1193 20.3864 32.044 20.1136 31.8935 19.8636C31.7429 19.6108 31.5284 19.4091 31.25 19.2585C30.9716 19.1051 30.642 19.0284 30.2614 19.0284H28.1136V22.0625ZM28.1136 18.108H30.0739C30.392 18.108 30.679 18.0455 30.9347 17.9205C31.1932 17.7955 31.3977 17.6193 31.5483 17.392C31.7017 17.1648 31.7784 16.8977 31.7784 16.5909C31.7784 16.2074 31.6449 15.8821 31.3778 15.6151C31.1108 15.3452 30.6875 15.2102 30.108 15.2102H28.1136V18.108Z"
              fill="white"
            />
            <path
              d="M37.2654 19.9458C37.4395 20.0128 37.5554 20.3171 37.6425 20.4587C38.0419 21.108 38.5001 21.7206 38.9459 22.3383C39.1187 22.5778 39.2952 22.8862 39.5237 23.082C39.6334 23.1761 39.8732 22.772 39.9023 22.7336C40.564 21.8604 41.2294 21.0013 42.0022 20.2203C44.3095 17.8885 46.9848 15.897 49.7154 14.0911C50.4625 13.597 51.2212 13.1249 51.9918 12.6686C52.294 12.4896 52.5993 12.3185 52.8878 12.118C52.993 12.0449 53.0935 11.9639 53.2046 11.9083"
              stroke="white"
              stroke-linecap="round"
            />
          </svg>

          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );

    default:
      return (
        <div>
          {/* <CiWarning size={30} color={"#0E2744"} /> */}
          {!props.window ? (
            <span className="nameSpan" style={{ paddingLeft: 10 }}>
              {props.item.display}
            </span>
          ) : null}
        </div>
      );
  }
}
