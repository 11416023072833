// config files for country codes
export const COUNTRY_CODES_LIST = [
    {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
    },
    {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
    },
    {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
    },
    {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
    },
    {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
    },
   
    {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
    },
];
