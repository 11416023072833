/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022\
 * Api Services page
 * */

/**
 * These components are used to display loading spinners and messages while data is being fetched
    or operations are being performed asynchronously.
 * This component displays a loading spinner (CircularProgress) along with an optional message.

  *It sets a timeout to simulate a longer loading time and displays a different message after a certain duration.

  *The component is positioned absolutely and covers the entire viewport with a semi-transparent background.
 */
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { messageUtil } from "../utils/messages";

export default function LoaderComponent() {
  const [takingLonger, setTakingLonger] = useState(false);
  const [message, setMessage] = useState(
    messageUtil.WAITING_MESSAGE_FOR_LOADER
  );

  setTimeout(() => {
    setTakingLonger(true);
  }, 5000);
  setTimeout(() => {
    setMessage("We are doing our best to fetch your data!");
  }, 10000);
  return (
    <div
      style={{
        zIndex: 999,
        position: "fixed",
        width: "100vw",
        height: "100vh",
        textAlign: "center",
        background: "white",
        opacity: 0.94,
      }}
    >
      <CircularProgress style={{ marginTop: "49vh" }} />
      {takingLonger ? <div style={{ color: "#3e5269" }}>{message}</div> : null}
    </div>
  );
}

export function AsyncLoaderComponent(props: any) {
  const [takingLonger, setTakingLonger] = useState(false);
  const [message, setMessage] = useState(
    messageUtil.WAITING_MESSAGE_FOR_LOADER
  );

  setTimeout(() => {
    setTakingLonger(true);
  }, 60000);
  setTimeout(() => {
    setMessage(`Failed to load ${props.parent} Data!`);
  }, 60000);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {takingLonger ? (
        <div style={{ color: "#3e5269", marginTop: "30vh" }}>{message}</div>
      ) : (
        <CircularProgress style={{ marginTop: "30vh" }} />
      )}
    </div>
 
  );
}

export function LoaderWithMessageComponent(props: any) {
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(props.message);
  }, [props]);
  return (
    <div
      style={{
        zIndex: 999,
        position: "absolute",
        width: "100vw",
        height: "200vh",
        textAlign: "center",
        background: "white",
        opacity: 0.92,
      }}
    >
      <CircularProgress style={{ marginTop: "49vh" }} />
     <div style={{ color: "#3e5269" }}>{message}</div>
    </div>
  );
}
