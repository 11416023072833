/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 * 
 * 404! all requests with url not found lands here.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useNavigate} from 'react-router-dom';

const getxsValue = (isfor: string, windowFlag: boolean) => {
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}


/** Actual Not found page function starts here */
export function PageNotFound() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
function backToDash (){
  navigate('/dashboard')
}
  return (
    <Grid container style={{ width: "100vw" }}>
      <Grid item xs={getxsValue("navbar", windowFlag)}>
        <Navbar path={'404'}/>
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag)}>
        <div className="notFoundContainer">
          <div className="notFound404Text">404!</div> <br />
          <div>Sorry! Requested Page Not Found!</div>
          <div className="backTo" onClick={backToDash}>Back to Dashboard</div>
        </div>
      </Grid>
    </Grid>
  );
}
