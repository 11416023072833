/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 */
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import { NavIconProvider } from "../components/navIconComponents/navIconComponents";
import {
  FreeTrial
} from "../components/navbarPromoComponents/navbarPromos";
import { constants } from "../constants/constants";
// import { ReactComponent as DoubtboxLogo } from "../assets/logo/doubtbox_logo.svg";
import { ReactComponent as DbGoscoreLogo } from "../assets/logo/db_goscore_logo.svg";
import { Grid } from "@mui/material";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const Navbar = (props: { path: string }) => {
  const [modifiedNavBarItems, setModifiedNavBarItems] = useState(constants.NAVIGATION_ITEMS);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const link = props.path;
  const navigate = useNavigate();

  // Hiding Worksheets for classess 11 and 12 Starts
  // Can comment this part to disable the logic
  const [profileData, setProfileData] = useState({
    standard: 0,
    syllabus: "",
    name: "",
    userName: "",
    hasTrialSession: false
  });


  useEffect(() => {
    if (!profileData.name) {
      var prof = localStorage.getItem("profileData");

      if (typeof prof !== "undefined" && prof !== null)
        setProfileData(JSON.parse(prof));
    }

  }, []);
  useEffect(() => {
    if (profileData.standard === 11 || profileData.standard === 12) {
      const itemToRemove = "Worksheets";
      // Filter out the object with display "Worksheets"
      let items = constants.NAVIGATION_ITEMS.filter((item) => item.display !== itemToRemove);
      setModifiedNavBarItems(items)
    }
  }, [profileData]);
  // Hiding Worksheets for classess 11 and 12 Ends
  
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // hides the navbar when screen size is smaller than defined breakpoints
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? true : false;

  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  let openClose = () => {
    // if (window === false) {
    //   setWindow(true);
    // } else {
    //   setWindow(false);
    // }
    console.log("open close disabled");
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  if (hideNavBar === false) {
    return (
      <nav
        className="navbar-menu"
        style={{ width: windowFlag === false ? 207 : 60 }}
      >
        <div className="burger" onClick={() => openClose()}>
          {!windowFlag ? <DbGoscoreLogo className="dbLogo" height={50} onClick={() => navigateTo("/dashboard")} /> : <DbGoscoreLogo onClick={() => navigateTo("/dashboard")} />}
        </div>
        <ul className="navbar__list">
          {modifiedNavBarItems.map((item, i) => (
            <Link
              style={{ textDecoration: "none" }}
              to={item.to}
              key={i}
            >
              <div className="navbar__li-box" key={i}>
                <NavIconProvider
                  item={item}
                  window={windowFlag}
                  selected={link}
                />
                <li
                  className="navbar__li"
                  style={{
                    display: windowFlag === false ? "inline-block" : "none",
                  }}
                >
                  {/* {item[0]} */}
                </li>
              </div>
            </Link>
          ))}
        </ul>
        <FreeTrial windowFlag={windowFlag} />
        {/* <Upgrade  windowFlag={windowFlag}/> */}
      </nav>
    );
  } else {
    return null;
  }
};

export default Navbar;
