import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Navbar from "../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import { services } from "../../services/api_service";
import {
  SelectChapter,
  SelectSubjectComponent,
} from "../../components/uiComponents/uiComponents";
import LoaderComponent from "../../shared/loader/loader";
import AlertService from "../../shared/alert/alertComponent";
import { messageUtil } from "../../shared/utils/messages";
import swal from "sweetalert";
import { removeEnglish } from "../../shared/utils/utilityFunctions";

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Worksheets page function starts here */
export function WorkSheetsPagePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataFromDashboard, setDataFromDashboard] = useState(location?.state);

  const [stage, setStage] = useState("stage1");

  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState<string[]>([]);

  const [sub, setSubject] = useState("");
  const [subId, setSubjectId] = useState("");
  const [loader, setLoader] = useState(true);

  const [profileData, setProfileData] = useState({
    syllabus: "",
    standard: "",
    userName: "",
  });
  const [userData, setUserData] = useState({
    mobile: "",
  });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };
  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [dataFromDashboard]);
  useEffect(() => {
    function fetchUserData() {
      var usr = localStorage.getItem("userData");

      if (typeof usr !== "undefined" && usr !== null) {
        setUserData(JSON.parse(usr));
      }
    }
    fetchUserData();
  }, []);
useEffect(()=>{
  console.log("dataFromDashboard",dataFromDashboard)
  if (dataFromDashboard){
    console.log('fetching selectSubject')
    setStage('2')
    selectSubject({subject:dataFromDashboard.name, id:dataFromDashboard.sub});

  }else{
    fetchData();
  }
},[profileData.syllabus])
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
    } else {
      navigateTo("/login");
    }
  };

  const fetchData = () => {
    setLoader(true);
    var val = localStorage.getItem("profileData");
    if (typeof val !== "undefined" && val !== null) {
      var data = JSON.parse(val);
      console.log(data)
      services
        .getSubjects(data.syllabus, data.standard)
        .then((res) => {
          setLoader(false);
          if (res.failed) return showAlert("error", res.message);
          else {
            // This function exist because in recorded classes, there are no content for english.
            // this function removes any subjects that have english in it (As requested.)
            // If English is made available remove the function and pass res.data to the setSubjectList function

            let arr = removeEnglish(res.data);
            setSubjectList(arr);
            // console.log(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
          showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    }
  };

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  function selectSubject(props:any) {
    setLoader(true);
    console.log(profileData);
    let apiData = {
      syllabusId: profileData.syllabus,
      standardId: profileData.standard,
      mobile: userData.mobile,
      user: profileData.userName,
      subject: props.id,
      userName:profileData.userName,
      source: constants.NCERT,
    };
    services
      .getChapter(apiData)
      .then((res) => {
        setLoader(false);

        if (res.failed) showAlert("error", res.message);
        else {
          setSubject(props.subject);
          setSubjectId(props.id);
          setStage("stage2");
          let temp = res.data.data
          temp.sort(function(a:any, b:any) {
            return parseInt(a.chapter_no) - parseInt(b.chapter_no);
        });
          setChapterList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function navigateTo(path: string, props?: any) {
    navigate(path, props);
  }
  function selectChapter(props:any) {
    // swal({
    //   title: "Work sheet will be available soon...",
    //   icon: "info",
    // });
    // return

    setLoader(true);
    localStorage.setItem(
      "selectedChapter",
      JSON.stringify(props.name)
    );
    console.log(props);

    // value is the selected chapter id
    services.getWorkSheetsList(props.id).then((res) => {
      setLoader(false);
     
      console.log(res);
      if (res.failed) return showAlert("error", res.message);
      else {
        console.log(res.data);
        if(res.data.data.length === 0)
        return swal({
            title: "Sorry! No Worksheets available",
            icon: "info",
            timer:3000
          });
        let state = {
          Tree: res.data.data,
        //   Tree:[
        //     {
        //         "chapter": "14",
        //         "content_uuid": "NCERT_Solutions~1DyX2BtwLZqMFI803hl5Aodjgmp4vNUP",
        //         "charges": "1.00",
        //         "pgtype": "1",
        //         "is_archived": "No",
        //         "textbook": "2",
        //         "standard": "1",
        //         "s3url": "s3://medias.doubtbox.com/1/1/1/3/2/14/1676632382-Evaluation PDF.pdf",
        //         "syllabus": "1",
        //         "content_type": "NCERT_Solutions",
        //         "content_orginalname": "1676632382-Evaluation PDF.pdf",
        //         "id": "1DyX2BtwLZqMFI803hl5Aodjgmp4vNUP",
        //         "subject": "3"
        //     }
        // ],
          proprietaryData: {
            chapter: props.id,
            textbook:props.textbook,
            subject: subId,
          },
        };
        navigateTo("/worksheets/contents", { state: state });
        // setStage("stage3");
      }
    });
  }

  function handleClickBc(prop: string) {
    setStage(prop);
    console.log(prop);
    if (prop === "stage1") fetchData();
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => handleClickBc("stage1")}
    >
      Worksheets
    </Link>,
    // <Link
    //   underline="hover"
    //   key="2"
    //   color="inherit"
    //   // href="/material-ui/getting-started/installation/"
    //   onClick={()=>handleClickBc('stage2')}
    // >
    //   {sub}
    // </Link>,
    <Typography key="3" color="text.primary">
      {sub}
    </Typography>,
  ];

  return (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />
      <Grid container className="fade-in-animate">
        {loader && <LoaderComponent />}
        <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
          <Navbar path={"worksheets"} />
        </Grid>
        <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
          <TopBarComponent
            logo={false}
            search={true}
            profileData={profileData}
          />
          {stage === "stage1" ? (
            <div className="nameContainer">
              <span className="nameContainer nameSpan">
                Elevate your learning with worksheets!
              </span>
              <br />
              <span className="nameContainer assessmeCaptchaSpan">
                Practice will make you perfect.
              </span>
            </div>
          ) : null}
          {stage === "stage2" ? (
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              style={{ marginTop: "28px", marginLeft: "25px" }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          ) : null}

          <div>
            {stage === "stage1" && subjectList.length === 0 && !loader ? (
              <div className="NoDataLearnOn">{messageUtil.NO_SUBJECTS}</div>
            ) : (
              stage === "stage1" &&
              subjectList.length !== 0 && (
                <SelectSubjectComponent
                  selectSubject={selectSubject}
                  subjectList={subjectList}
                  status={constants.LEARN_ON_STATUS}
                  profileData={profileData}
                  caseValue={'b'}//case 'b' for Recorded videos, worksheets and mocktest
                />
              )
            )}
            {stage === "stage2" && chapterList.length === 0 && !loader ? (
              <div className="NoDataLearnOn">{messageUtil.NO_CHAPTERS}</div>
            ) : (
              stage === "stage2" &&
              chapterList.length !== 0 && (
                <SelectChapter
                  selectChapter={selectChapter}
                  parent={"learn-on"}
                  chapterList={chapterList}
                />
              )
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}