// This page contains components that is shown in the nav bar.
// We can create new components for promotional offer banners, and recommended classes etc.. here.
// components must be imported to NavBar.tsx

import React, {  useState } from "react";
import "./navbarPromos.scss";

import { ReactComponent as Promo1 } from "../../assets/icons/promo1.svg";
import { CouponModal } from "../uiComponents/modals";

export function FreeTrial(windowFlagRef: { windowFlag: boolean }) {
  const [open, setOpen] = useState(false)
  function openCoupon(){
    setOpen(true)
  }
  const handleClose = () => {
    console.log(false);
    setOpen(false);
  };
  return !windowFlagRef.windowFlag ? (
    <>
     <CouponModal
    open={open}
    setOpen={setOpen}
   handleClose={handleClose}
  />
    <div className="freeTrialContainer" onClick={openCoupon}>
      <div className="headLabel">LEARN ON</div>
      <div className="subLabel" >Click here to activate!</div>
      <Promo1
        style={{
          width: "100%",
        }}
      />
    </div>
    </>
   
  ) : (
    <div></div>
  );
}
export function Upgrade(windowFlagRef: { windowFlag: boolean }) {
  return !windowFlagRef.windowFlag ? (
    <div className="freeTrialContainer upgradeContainer">
      <div className="headLabel">Let’s upgrade to premium feature</div>
      <div className="subLabel Upgradebutton">Upgrade</div>
      <Promo1
        style={{
          width: "100%",
          marginTop: "25px",
        }}
      />
    </div>
  ) : (
    <div></div>
  );
}
