import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Navbar from "../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import {
  SelectChapter,
  SelectSubjectComponent,
} from "../../components/uiComponents/uiComponents";
import "./AssessMe.scss";
import { services } from "../../services/api_service";
import LoaderComponent from "../../shared/loader/loader";
import { messageUtil } from "../../shared/utils/messages";
import AlertService from "../../shared/alert/alertComponent";
import {
  setToLocalStorage,
  getFromLocalStorage,
  formatSecTommss,
} from "../../shared/utils/utilityFunctions";
import swal from "sweetalert";

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Assess me page function starts here */
export function AssessMePage() {
  const navigate = useNavigate();
  const locatioData = useLocation();
  console.log(locatioData?.state);
  const [stage, setStage] = useState(
    locatioData?.state ? locatioData?.state : "stage1"
  );
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState<string[]>([]);
  const chapterName = getFromLocalStorage("selectedChapterArr");
  const [sub, setSubject] = useState("");
  // const [subId, setSubjectId] = useState("");

  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
  });
  const [userData, setUserData] = useState({
    mobile: "",
  });
  const [loader, setLoader] = useState(true);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    validateLoggedIn();
    fetchData();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    function fetchUserData() {
      var usr = localStorage.getItem("userData");

      if (usr !== undefined && usr !== null) {
        setUserData(JSON.parse(usr));
      }
    }
    fetchUserData();
  }, []);
  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };
  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  const fetchData = () => {
    setLoader(true);
    var val = localStorage.getItem("profileData");

    if (val !== undefined && val !== null) {
      var data = JSON.parse(val);
      services
        .getSubjects(data.syllabus, data.standard)
        .then((res) => {
          setSubjectList(res.data);
          setLoader(false);
        })
        .catch((e) => {
          console.log(e);
          setLoader(false);
          showAlert("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    }
  };
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (val !== undefined && val !== null) setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => handleClickBc("stage1")}
    >
      Assess Me
    </Link>,
    <Typography key="3" color="text.primary">
      {sub}
    </Typography>,
  ];
  const stage3breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => handleClickBc("stage1")}
    >
      Assess Me
    </Link>,
    <Typography key="2" color="text.primary">
      {sub}
    </Typography>,

    <Typography key="3" color="text.primary">
      {chapterName}
    </Typography>,
  ];
  function selectSubject(props: any) {
    console.log(props);
    setLoader(true);

    let apiData = {
      syllabusId: profileData.syllabus,
      standardId: profileData.standard,
      mobile: userData.mobile,
      user: profileData.userName,
      subject: props.id,
      userName: profileData.userName,
      source: constants.VIDEOS,
    };
    services
      .getChapter(apiData)
      .then((res) => {
        setLoader(false);

        if (res.failed) showAlert("error", res.message);
        else {
          setSubject(props.subject);
          setToLocalStorage("selectedSubject", props.subject);
          // setSubjectId(props.id);
          setStage("stage2");
          let temp = res.data.data;
          temp.sort(function (a: any, b: any) {
            return parseInt(a.chapter_no) - parseInt(b.chapter_no);
          });
          setChapterList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function navigateTo(path: string) {
    navigate(path);
  }
  // function selectChapter(value: any) {
  function selectChapter(props: any) {
    console.log(props);
    setLoader(true);
    const selectedChapterArr: string[] = [];
    selectedChapterArr.push(props.name);
    localStorage.setItem(
      "selectedChapterArr",
      JSON.stringify(selectedChapterArr)
    );
    console.log(profileData);
    let apiData = {
      type: "PracticeTest",
      userName: profileData.userName,
      selection: {
        content_type: "Assessment",
        pgtype: 1,
        syllabus: profileData.syllabus,
        standard: profileData.standard,
        subject: props.subject,
        subjectName: sub, //value is in full
        textbook: props.textbook,
        textbookName: sub,
        chapter: props.id,
        chapterName: props.name,
      },
    };
    console.log(apiData);
    services.getPracticeTest(apiData).then((res) => {
      setLoader(false);

      // res=tempQuesion //Here

      console.log(res);
      if (res.failed)
        return showAlert(
          "error",
          messageUtil.ASSESSME_FETCH_FAILED + " ERROR: " + res.message
        );
      else {
        if (res.message === "Get questions unsuccessfull !") {
          return swal({
            title: "No questions found",
            icon: "error",
            timer: 3000,
          });
        }
        let packageId = res.data.package_id;
        setToLocalStorage("packageId", packageId);
        let allQuestions = res.data.questions;
        let totalTime = res.data.totaltime;
        for (let one in allQuestions) {
          let oneData: any;
          oneData = allQuestions[one];
          oneData.answersDone = {};
          oneData.marked = false;
        }
        console.log(allQuestions);
        setToLocalStorage("practiceQuestions", allQuestions);
        setToLocalStorage("totalTime", totalTime);
        // setToLocalStorage("practiceQuestions",res.data.questions )

        setStage("stage3");
      }
    });
  }

  function handleClickBc(prop: string) {
    setStage(prop);
    if (prop === "stage1") fetchData();
    console.log(prop);
  }

  return (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />
      {
        loader ? <LoaderComponent /> :
          <Grid container className="fade-in-animate">

        <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
          <Navbar path={"assess-me"} />
        </Grid>
        <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
          <TopBarComponent
            logo={false}
            search={true}
            profileData={profileData}
          />
          {stage === "stage1" ? (
            <div className="nameContainer">
              <span className="nameContainer nameSpan">
                {/* Assess your knowledge! */}
                Subjects
              </span>
              <br />
              <span className="nameContainer assessmeCaptchaSpan">
                Take a test on your subjects
              </span>
            </div>
          ) : null}
          {stage === "stage2" ? (
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              style={{ marginTop: "28px", marginLeft: "25px" }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          ) : null}
          {stage === "stage3" ? (
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              style={{ marginTop: "28px", marginLeft: "25px" }}
            >
              {stage3breadcrumbs}
            </Breadcrumbs>
          ) : null}

          <div>
            {stage === "stage1" ? (
              <SelectSubjectComponent
                selectSubject={selectSubject}
                subjectList={subjectList}
                status={constants.ASSESS_ME_STATUS}
                profileData={profileData}
                caseValue={"a"} //case 'a' for Learn on and assess me
              />
            ) : null}
            {stage === "stage2" ? (
              <SelectChapter
                selectChapter={selectChapter}
                parent={"assess-me"}
                chapterList={chapterList}
              />
            ) : null}
            {stage === "stage3" ? (
              <StartTestPreview navigateTo={navigateTo} />
            ) : null}
          </div>
        </Grid>
      </Grid>
      }

    </>
  );
}

function StartTestPreview(props: { navigateTo: any }) {
  var chapterNames;
  var val = localStorage.getItem("selectedChapterArr");
  const questions = getFromLocalStorage("practiceQuestions");
  const totalTime = getFromLocalStorage("totalTime");

  if (val !== undefined && val !== null) {
    chapterNames = JSON.parse(val);
  }
  console.log(chapterNames);
  function proceed() {
    props.navigateTo("/assess-me/test");
  }
  return (
    <Grid className="PreviewPageContainer" container item>
      <Grid className="chapterListContainer" item xs={12} md={8}>
        {/* Task 1228: Suggested Changes -UI */}
        {/* <span className="assessMeChapterText">Chapters</span> */}
        <span className="assessMeChapterText">Chapter</span>
        {chapterNames &&
          chapterNames !== null &&
          chapterNames.map((name: string, index: any) => (
            <div className="assessMeChapterText chapters" key={index}>
              {/* Task 1228: Suggested Changes -UI */}
              {/* {index + 1}. {name} */}
              {name}
            </div>
          ))}
      </Grid>
      <Grid item xs={11} md={8} style={{ marginTop: 35 }}>
        <span className="hedderTextGI">General Instructions</span>

        <ul className="a">
          <li>Please keep a pen and paper ready for rough work</li>
          <li>
            Your test will automatically end when the allotted time for the test
            runs out
          </li>
          <li>
            You will see how many questions you have attempted, answered and
            skipped on the top
          </li>
          <li>You can change the answers to your questions</li>
          <li>
            Your time countdown will begin as soon as you click the start button
          </li>
          <li>
            You can attempt any question during a test using Question palette,
            Next and Previous button.
          </li>
          <li>
            During a test, you can move backwards and forwards and jump to any
            question you like
          </li>
        </ul>
        <span className="hedderTextGI">
          NEP 2020 compliance with Competency based Questions
        </span>

        <ul className="a">
          <li>
            <strong> Remembering:</strong> Exhibit memory of previously learned
            material by recalling facts, terms, basic concepts, and answers.
          </li>
          <li>
            <strong>Understanding:</strong> Demonstrate understanding of facts
            and ideas by organizing, comparing, translating, interpreting,
            giving descriptions, and stating main ideas.
          </li>
          <li>
            <strong>Applying:</strong> Solve problems to new situations by
            applying acquired knowledge, facts, techniques and rules in a
            different way.
          </li>
          <li>
            <strong>Analysing:</strong> Examine and break information into parts
            by identifying motives or causes. Make inferences and find evidence
            to support generalizations.
          </li>
          <li>
            <strong>Evaluating:</strong> Present and defend opinions by making
            judgments about information, validity of ideas, or quality of work
            based on a set of criteria.
          </li>
          <li>
            <strong> Creating:</strong> Compile information together in a
            different way by combining elements in a new pattern or proposing
            alternative solutions.
          </li>
        </ul>
      </Grid>
      <Grid container item md={4} className="chapterListContainer details">
        <Grid item xs={12}>
          <Grid item xs={12} className="reportsLabel">
            Total Questions
          </Grid>

          <Grid item xs={12} className="reportsValue">
            {questions.length}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} className="reportsLabel">
            Total Time
          </Grid>

          <Grid item xs={12} className="reportsValue">
            {formatSecTommss(totalTime)} Min
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 45 }}>
        <div className="proceedButton" onClick={proceed}>
          Start Test
        </div>
      </Grid>
    </Grid>
  );
}
