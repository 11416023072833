/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 */
import React, { useEffect, useState } from "react";
import {  constants } from "../../../constants/constants";
import { Grid } from "@mui/material";

import { useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import "./AssessMeTest.scss";

import { ReactComponent as TimerLogo } from "../../../assets/icons/timer.svg";
import MCQComponent from "../assessMeComponents/MCQComponent";
import TFComponent from "../assessMeComponents/TFComponent";
import OWQComponent from "../assessMeComponents/OWQComponent";
import FIBComponent from "../assessMeComponents/FIBComponent";
import MTFComponent from "../assessMeComponents/MTFcomponent";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import { ConfirmAssessmentSubmit } from "../../../components/uiComponents/modals";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { messageUtil } from "../../../shared/utils/messages";
import swal from "sweetalert";

interface QUESTION_TYPES_INTERFACE {
  MCQ: string;
  TrueORFalse: string;
  OneWordQuestion: string;
  FillInTheBlanks: string;
  MatchTheFollowing: string;
}
export function AssessMeTestPage() {
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<any[]>([]);
  const [currQuestion, setCurrentQuestion] = useState<any[]>([]);
  var [currQnindex, setCurrQnIndex] = useState(0);
  const [counterValue, setCounterValue] = useState("");
  const [profileData, setProfileData] = useState({ userName: "" });
  const [answeredData, setAnsweredData] = useState<any[]>([]);
  const [openConfirmBox, setopenConfirmBox] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  var [total_counter, setTotalCounter] = useState(0);
  var [lastAnswer, setLastAnswer] = useState<any>({});
  const [loader, setLoader] = useState(false);
  var now = new Date();
  const [qnStartTime, setStartTime] = useState(now.getTime());

  const handleOpen = () => {
    //  setOpen(true);
    console.log("More details");
  };
  const handleClose = () => {
    console.log(false);
    setopenConfirmBox(false);
  };
  useEffect(() => {
    timerRun(getFromLocalStorage('totalTime'));
    validateLoggedIn();
    getQuestions();
  }, []);

  useEffect(() => {
    if (questions.length > 0 && currQuestion.length === 0)
      setCurrentQuestion(questions[0]);
  }, [questions]);

  const getQuestions = async () => {
    let temp = [];
    var questions = await getFromLocalStorage("practiceQuestions");
    // console.log(questions);
    for (let i = 0; i < questions.length; i++) {
      temp[i] = {};
    }
    setAnsweredData(temp);
    if (questions) return setQuestions(questions);
    else return setQuestions([]);
  };
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

       if (val !== undefined && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  // var questions = [];
  function backToDash() {
    navigate("/dashboard");
  }
  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    // console.log(event.target.value, id);
    let currQn: any = questions[currQnindex];
    currQn.marked = true;
    // console.log(questions)
    setQuestions(questions);
  };

  const markAnswer = (event: React.ChangeEvent<HTMLInputElement>, id: any) => {
    // console.log(event, id);
  };

  function nextQuestion(props?: any, marked?:boolean) {
    console.log(props,marked);
    questions[currQnindex].answersDone = props;
    // let temp = getFromLocalStorage("submittedArray");
    let temp = answeredData;
    if (temp.length === 0) {
      temp.push(props);
    } else {
      temp[currQnindex] = props;
      temp[currQnindex].marked = marked    }
    // console.log(temp);
    props.userName = profileData.userName;
    console.log(props);

    setAnsweredData(temp);
    var now = new Date();
    setStartTime(now.getTime());
    // setToLocalStorage("submittedArray", temp);
    // return
    if (currQnindex + 1 < questions.length) {

      setLoader(true);

      services.submitAnswer(props).then((res: any) => {
        setLoader(false);

        console.log(res);
        if (res.failed) swal({
          title: res.message,
          text:messageUtil.ANSWER_SUBMISSION_FAILED,
          icon: "error",
        }); 
        else {
          console.log(res);
          setCurrQnIndex(++currQnindex); //Incrementing value then calling setCurrQnIndex
          setCurrentQuestion([]);
          setCurrentQuestion(questions[currQnindex]);
        }
      });
    } else {
      setLastAnswer(props);
      setopenConfirmBox(true);
    }
  }
  function timerRun(seconds: number) {
    let counter = seconds;
    function convertAndSet(counter: number) {
      const result = new Date(counter * 1000).toISOString().slice(11, 19);
      setCounterValue(result);
    }
    const interval = setInterval(() => {
      --counter;
      setTotalCounter(++total_counter);
      convertAndSet(counter);
      if (counter <= 0) {
        clearInterval(interval);
        swal({
          title: "Time Out!",
          text: "Assessment ended",
          icon: "info",
        }).then((response) => {
          let packageId = getFromLocalStorage("packageId");
          let apiData = {
            userName: profileData.userName,
            package_id: packageId,
            timeconsumed: total_counter,
          };
          finishExam(apiData);
        });
      }
    }, 1000);
  }

  function prevQuestion() {
    if (currQnindex === 0) return false;
    else {
      setCurrQnIndex(--currQnindex); //Decrementing value then calling setCurrQnIndex
      setCurrentQuestion(questions[currQnindex]);
    }
  }
  function review() {
    console.log("review");
    handleClose();
    // setCurrQnIndex(1);
    // setCurrentQuestion(questions[currQnindex]);
  }
  function submit() {
    handleClose();

    console.log("submit");

    console.log(total_counter);
    console.log(lastAnswer);
    setLoader(true);

    services.submitAnswer(lastAnswer).then((res: any) => {
      console.log(res);
      if (res.failed)   swal({
        title: res.message,
        text:messageUtil.ANSWER_SUBMISSION_FAILED,
        icon: "error",
      });
      else {
        let temp = answeredData;
        temp[answeredData.length - 1] = lastAnswer;
        setAnsweredData(temp);
        console.log("submission successfull! attempting to finish the exam..");
        let packageId = getFromLocalStorage("packageId");
        let apiData = {
          userName: profileData.userName,
          package_id: packageId,
          timeconsumed: total_counter,
        };
        console.log(apiData);

        setTimeout(() => {
          finishExam(apiData);
        }, 3000);
      }
    });
  }
  function finishExam(apiData: any) {
        services.finishExam(apiData).then((res: any) => {
          setLoader(false);
      if (res.failed)
        swal({
            title: res.message,
          text: messageUtil.FINISH_EXAM_FAILED,
            icon: "error",
          });
          else {
            console.log("Attempt successfull!");
            console.log(res.data.data);
            setToLocalStorage("assessMeResult", res.data.data);
            navigateTo("/reports/assess-me-report");
          }
        });
  }
  function selectQuestion(index: number) {
    console.log(index);
    setCurrQnIndex(index); //Decrementing value then calling setCurrQnIndex
    setCurrentQuestion(questions[index]);
  }
  const handleContextMenu = (e:any) => {
    e.preventDefault();
  }; 
  return (
    <Grid container onContextMenu={handleContextMenu}>
      {loader && <LoaderComponent />}

      <ConfirmAssessmentSubmit
        open={openConfirmBox}
        setOpen={setOpen}
        handleOpen={handleOpen}
        handleClose={handleClose}
        review={review}
        submit={submit}
      />
      <TopBarComponent logo={true} search={false} profileData={profileData} />

      <Grid item md={9} xs={12} style={{ marginTop: 30 }}>
        <QuestionComponent
          question={currQuestion}
          allQuestion={questions}
          markForReview={markForReview}
          markAnswer={markAnswer}
          nextQuestion={nextQuestion}
          prevQuestion={prevQuestion}
          counterValue={counterValue}
          answeredData={answeredData}
          currQnindex={currQnindex}
          qnStartTime={qnStartTime}
        />
      </Grid>
      <Grid container item xs={12} md={3} style={{ marginTop: 30 }}>
        <QuestionsDashboard
          questions={questions}
          currQuestion={currQuestion}
          currQnindex={currQnindex}
          answeredData={answeredData}
          selectQuestion={selectQuestion}
        />
      </Grid>
    </Grid>
  );
}

function QuestionsDashboard(props: {
  questions: any;
  currQuestion: any;
  currQnindex: any;
  answeredData: any;
  selectQuestion: any;
}) {
  useEffect(() => {
    console.log(props.answeredData)
  }, []);

  return (
    <Grid container className="QDContainer">
      <Grid item className="labels label1" xs={12}>
        Question Type :{" "}
        {
          constants.QUESTION_TYPES[
            props.currQuestion.type as keyof QUESTION_TYPES_INTERFACE
          ]
        }
      </Grid>
      <Grid item className="labels label2" xs={12}>
        Questions:{" "}
        <span className="labels label3">
          {props.currQuestion.qn} / {props.questions.length}
        </span>
      </Grid>

      <Grid
        container
        className="palletteContainer hideScrollBar"
        style={{ width: "100%", paddingLeft: "20px" }}
        item
        xs={12}
      >
        {/* {props.questions.map(({ qn }: any) => (
          <Grid item xs={2.4} key={qn}>
           
            {props.currQuestion.qn === qn ? (
              <div className="palette notAttempted">{qn}</div>
            ) : null}


          </Grid>
        ))} */}
        {props.answeredData.map((data: any, index: number) => (
          <Grid item xs={2.4} key={data.qn}>
            { data.marked ? (
              <div
                className="palette marked"
                onClick={() => props.selectQuestion(index)}
              >
                {index + 1}
              </div>
            ) :
            index === props.currQnindex ? (
              <div
                className="palette"
                onClick={() => props.selectQuestion(index)}
              >
                {index + 1}
              </div>
            ) : data.answer && !data.answer.skipped ? (
              <div
                className="palette answered"
                onClick={() => props.selectQuestion(index)}
              >
                {index + 1}
              </div>
            ) : data.answer && data.answer.skipped ? (
              <div
                className="palette notAnswered"
                onClick={() => props.selectQuestion(index)}
              >
                {index + 1}
              </div>
            ) :  (
              <div
                className="palette notAttempted"
                onClick={() => props.selectQuestion(index)}
              >
                {index + 1}
              </div>
            )}
          </Grid>
        ))}
      </Grid>

      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          marginLeft: "0px",
          marginTop: "5px",
          height: "50px",
        }}
      >
        <Grid container item xs={12} className="">
          <Grid item xs={0.4} className="greenCircle"></Grid>
          <Grid
            item
            xs={6.6}
            style={{ paddingLeft: "5px" }}
            className="answeredLabel"
          >
            Answered
          </Grid>
          <Grid item xs={0.4} className="redCircle"></Grid>
          <Grid
            item
            xs={4.6}
            style={{ paddingLeft: "5px" }}
            className="answeredLabel"
          >
            Not Answered
          </Grid>
        </Grid>
        <Grid container item xs={12} className="">
          <Grid item xs={0.4} className="greyCircle"></Grid>
          <Grid
            item
            xs={6.6}
            style={{ paddingLeft: "5px" }}
            className="answeredLabel"
          >
            Not Attempted
          </Grid>
          <Grid item xs={0.4} className="blueCircle"></Grid>
          <Grid
            item
            xs={4.6}
            style={{ paddingLeft: "5px" }}
            className="answeredLabel"
          >
            Review
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function QuestionComponent(props: {
  question: any;
  markForReview: any;
  markAnswer: any;
  nextQuestion: any;
  prevQuestion: any;
  counterValue: any;
  allQuestion: any;
  answeredData: any;
  currQnindex: any;
  qnStartTime: any;
}) {
  const [reRender, setreRender] = useState(0);
  useEffect(() => {}, [reRender, props.question]);

  return (
    <>
      <Grid container spacing={1} style={{ paddingLeft: "10vw" }}>
        <Grid item xs={7} md={9}>
          <span className="questionNo"> Question {props.question.qn}</span>
        </Grid>
        <Grid container item xs={5} md={3}>
          <div className="timerBg">
            <TimerLogo />
          </div>
          <div>
            <span className="timerLabel">{props.counterValue}</span>
          </div>
        </Grid>
      </Grid>
      {props.question.type === "MCQ" ? (
        <MCQComponent
          question={props.question}
          markAnswer={props.markAnswer}
          nextQuestion={props.nextQuestion}
          prevQuestion={props.prevQuestion}
          reRender={setreRender}
          answeredData={props.answeredData}
          currQnindex={props.currQnindex}
          qnStartTime={props.qnStartTime}
        />
      ) : null}
      {props.question.type === "TrueORFalse" ? (
        <TFComponent
          question={props.question}
          markAnswer={props.markAnswer}
          nextQuestion={props.nextQuestion}
          prevQuestion={props.prevQuestion}
          answeredData={props.answeredData}
          currQnindex={props.currQnindex}
          qnStartTime={props.qnStartTime}
        />
      ) : null}

      {props.question.type === "OneWordQuestion" ? (
        <OWQComponent
          question={props.question}
          markAnswer={props.markAnswer}
          nextQuestion={props.nextQuestion}
          prevQuestion={props.prevQuestion}
          answeredData={props.answeredData}
          currQnindex={props.currQnindex}
          reRender={setreRender}
          qnStartTime={props.qnStartTime}
        />
      ) : null}

      {props.question.type === "FillInTheBlanks" ? (
        <FIBComponent
          question={props.question}
          markAnswer={props.markAnswer}
          nextQuestion={props.nextQuestion}
          prevQuestion={props.prevQuestion}
          answeredData={props.answeredData}
          currQnindex={props.currQnindex}
          qnStartTime={props.qnStartTime}
        />
      ) : null}
      {props.question.type === "MatchTheFollowing" ? (
        <MTFComponent
          question={props.question}
          markAnswer={props.markAnswer}
          nextQuestion={props.nextQuestion}
          prevQuestion={props.prevQuestion}
          answeredData={props.answeredData}
          currQnindex={props.currQnindex}
          qnStartTime={props.qnStartTime}
        />
      ) : null}
    </>
  );
}
