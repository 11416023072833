import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import "../../learnOn/LearnOn.scss";
import ReactPlayer from "react-player";
import Button from "@mui/material/Button";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";
import ListAltIcon from '@mui/icons-material/ListAlt';
import swal from "sweetalert";

export function WorksheetsContents() {
  const location = useLocation();
  console.log(location.state)
  const [tree, setTree] = useState(location.state.Tree);
  const [contentData, setContentData] = useState(
    location.state.proprietaryData
  );
  const [contentType, setContentType] = useState("NCERT_Solutions");
  const [isPlaying, setPlaying] = useState(true);

  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
  });
  const [listData, setListData] = useState([
    { content_orginalname: "", content_uuid: "" },
  ]);
  const [nextContentType, setNextContentType] = useState("Textbook");

  // for alert messaging

  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const selectedChapter = getFromLocalStorage("selectedChapter");
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    // setAlertShow(false);
  };
  const [loader, setLoader] = useState(false);

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }
  const [dataUrl, setDataUrl] = useState("");

  useEffect(() => {
    validateLoggedIn();
  }, []);

  useEffect(() => {
    selectContent(tree[0].content_uuid);
  }, [tree]);

  useEffect(() => {
    setPlaying(true);
    console.log("dataUrl", dataUrl);
  }, [dataUrl]);

  const navigate = useNavigate();

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }

  function selectContent(selectedData: string) {
    setLoader(true);
    console.log(selectedData);
    if (selectedData) {
      services.getSignedUrl(selectedData).then((res) => {
        console.log(res)
        setLoader(false);
        if (res.failed) return showAlert("error", res.message);
        else {
          console.log(res.data.data.url);
          setDataUrl(res.data.data.url);
        }
      });
    }
  }
  function goBack() {
    let state = { sub: contentData.subject, name: contentData.subjectName };

    navigate("/worksheets", { state: state });
  }
  return (
    <div className="SettingsProfileContainer">
      {loader && <LoaderComponent />}
      <TopBarComponent search={false} logo={true} profileData={profileData} />
      <Grid container className="contentsPageLayout">
        <Grid item xs={12} sm={4} className="p-14">
          <Grid className="learnOnLayout1  p-14">
            <Grid container style={{ width: "25vw" }}>
              <Grid item xs={12} md={3} className="learnOnChapter" onClick={goBack}>
                Chapter :
              </Grid>
              <Grid item xs={12} md={9} className="learnOnChapterName">
                {selectedChapter}
              </Grid>
            </Grid>
            <Grid className="contentList hideScrollBar">
              {   tree.map((content: any) => (
                <ContentItem
                  key={content.content_uuid}
                  value={content}
                  selectContent={selectContent}
                />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={11} sm={7.5} className="contentContainer">
          <iframe
            title="Worksheets"
            style={{ width: "100%", height: "100%", margin: "5px" }}
            src={dataUrl}
          ></iframe>
        </Grid>
      </Grid>
    </div>
  );
}

function ContentItem(props: {
  key: string;
  value: {
    content_uuid: string;
    content_orginalname: string;
    TopicID: number;
    topicName: string;
    TopicNumber: number;
  };
  selectContent: any;
}) {
  //Content name from backend is like "1561716893-CHAPTER 4 – CARBON AND ITS COMPOUNDS" and cannot be renamed,
  //so trimming unwanted characters.
  // let contentName = props.value.content_orginalname.split("-")[1];
  let contentName = props.value.content_orginalname.substring(11);
  return (
    <Grid
      container
      className="eachContentCard"
      onClick={() => props.selectContent(props.value.content_uuid)}
    >
      <Grid item xs={1} md={1} className="eachContentL1 color1">
        <ListAltIcon />
      </Grid>
      <Grid item xs={11} md={11} className="eachContentL2">
        <div className="eachContentL2TopicName">
        {contentName.replace(".pdf", "")}
        </div>
        {/* <div className="eachContentL2Duration">03:15 min</div> */}
      </Grid>
    </Grid>
  );
}
