import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import "./assessMeComponents.scss";
import { constants } from "../../../constants/constants";
import {
  findTimeSpentOnQuestion,
  getFromLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import { useEffect, useState } from "react";

export default function MCQComponent(props: {
  markAnswer: any;
  question: any;
  prevQuestion: any;
  nextQuestion: any;
  reRender: any;
  answeredData: any;
  currQnindex: any;
  qnStartTime: any;
}) {
  function createMarkup(value: string) {
    return {
      __html: value,
    };
  }
  var [skipped, setSkipped] = useState(false);
  var [marked, setMarkForReview] = useState(false);
  var [markedArr, setmarkedArr] = useState([
    { value: false },
    { value: false },
    { value: false },
    { value: false },
  ]);

  useEffect(() => {
    setMarkForReview(props.answeredData[props.currQnindex].marked?true : false);
    console.log(props.answeredData);
    let currQn = props.answeredData[props.currQnindex];
    if (currQn && currQn.answer && currQn.answer.selectedanswer !== undefined) {
      // console.log("ansered aan");
      for (let i = 0; i < markedArr.length; i++) {
        markedArr[i].value = false;
      }
      let sel = 0;
      sel = props.answeredData[props.currQnindex].answer.selectedanswer;
      // console.log(sel)
      markedArr[sel].value = true;
      setmarkedArr(markedArr);
    } else
      setmarkedArr([
        { value: false },
        { value: false },
        { value: false },
        { value: false },
      ]);
    props.reRender(Math.random());
    console.log(props.question);
  }, [props.question]);
  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(event.target.value, id);
    setMarkForReview(marked => !marked)
    console.log(marked)

    
    // let currQn:any = questions[currQnindex];
    // currQn.marked= true;
    // // console.log(questions)
    // setQuestions(questions)
  };
  const markAnswer = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    // setMarkForReview(true);
   
    for (let i = 0; i < markedArr.length; i++) {
      markedArr[i].value = false;
    }
    // console.log(event.target.name, event.target.value);
    if (markedArr[id].value) markedArr[id].value = false;
    else markedArr[id].value = true;

    setmarkedArr(markedArr);
    // console.log(markedArr);
    // props.markAnswer()
    props.reRender(Math.random());
  };

  function changeQuestion(param: boolean) {
    // param true = next question
    // param false = previous question
    if (param) {
      let selected = undefined;
      for (let i = 0; i < markedArr.length; i++) {
        if (markedArr[i].value === true) selected = i;
      }
      let packageId = getFromLocalStorage("packageId");
      let apiData = {
        answer: {
          question_id: props.question.content_uuid,
          totaltime: findTimeSpentOnQuestion(props.qnStartTime),
          package_id: packageId,
          question_type: "MCQ",
          skipped: selected === undefined ? true : false,
          selectedanswer:
            selected === undefined ? undefined : selected.toString(),
        },
      };
      // console.log(apiData);

      props.nextQuestion(apiData, marked);
    } else {
      props.prevQuestion();
    }
  }
  function clear() {
    setmarkedArr([
      { value: false },
      { value: false },
      { value: false },
      { value: false },
    ]);
    props.reRender(Math.random());
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          item
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q)}
        >
          {/* {props.question.q} */}
        </Grid>
        {props.question.o.map((option: any, index: number) => {
          return (
            <Grid
              container
              key={index}
              className="optionsContainer"
              item
              xs={11}
              md={10.4}
            >
              <Grid item xs={2} md={1}>
                <Checkbox
                  style={{ marginTop: "7px" }}
                  onChange={(e) => {
                    markAnswer(e, index);
                  }}
                  id={constants.APLPHABETS[index]}
                  name={JSON.stringify(index)}
                  checked={markedArr[index].value}
                />
              </Grid>
              <Grid item className="optionsLabel" xs={1} md={0.5}>
                {constants.APLPHABETS[index]} &nbsp;
              </Grid>
              <Grid
                item
                className="mcqOption"
                dangerouslySetInnerHTML={createMarkup(option)}
                xs={9}
                md={10.5}
              ></Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
          <Checkbox
            onChange={(e) => {
              markForReview(e, props.question);
            }}
            checked={marked}
            defaultChecked={marked}
          />
          <span className="questionNo"> Mark for review</span>
        </Grid>
        <Grid container item xs={2.5}>
          <span onClick={() => clear()} className="clearButton">
            Clear
          </span>
        </Grid>
        <Grid container item xs={1.5}>
          <span
            className="nextButton previous"
            onClick={() => changeQuestion(false)}
          >
            Previous
          </span>
        </Grid>
        <Grid container item xs={2}>
          <span className="nextButton" onClick={() => changeQuestion(true)}>
            Next
          </span>
        </Grid>
      </Grid>
    </>
  );
}
