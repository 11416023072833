/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Assess Me reports page.
 */
import { Grid, styled } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import { constants } from "../../../constants/constants";
import LoaderComponent from "../../../shared/loader/loader";
import Navbar from "../../../shared/Navbar";
import {
  getDate,
  getFromLocalStorage,
  formatSecTommss,
} from "../../../shared/utils/utilityFunctions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} size="116px" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={30}
        >{Math.round(props.value) + '%'}</Typography>
      </Box>
    </Box>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#0E2744" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  width: "100%",
  textAlign: "left",
  color: theme.palette.mode === "dark" ? "#fff" : "#3E5269",
  border: "2px solid #D8DCE1",
  margin: "10px",
  borderRadius: "8px",
}));

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function AssessMeReportPage() {
  const navigate = useNavigate();
  const reportData = getFromLocalStorage("assessMeResult");
  const summary = reportData.Summary ? reportData.Summary : {};
  const topicReport = reportData.TopicReport ? reportData.TopicReport : [];
  const result = reportData.Result ? reportData.Result : [];
  const subject = getFromLocalStorage("selectedSubject");
  const chapter = getFromLocalStorage("selectedChapterArr");
  console.log(summary, topicReport, result);
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
  });
  const [loader, setLoader] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
      // if (reportData.length === 0) navigateTo("/dashboard");
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  console.log(reportData);
  function getOverallReport() {
    navigateTo("/reports/overall-report");
  }
  
  function getQnWiseReport() {
    navigateTo("/reports/question-report");
  }
  return (
    <Grid container>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"assess-me"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        />
        <Grid
          container
          spacing={2}
          item
          xs={11}
          className="reportsMainContainer"
          style={{ marginTop: "20px" }}
        >
          <Grid item xs={12} md={2}>
            <CircularProgressWithLabel value={summary.percentage} />
          </Grid>
          <Grid container item xs={12} md={8}>
            <Grid className="reportSubContainer1" item xs={12}>
              <Grid container item xs={12}>
                <Grid item xs={6} sm={2}>
                  <div className="reportsLabel">Class</div>
                  <div className="reportsValue">
                    Class {profileData.standard}
                    {/* {profileData.standard + " " + profileData.syllabusName} */}
                  </div>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <div className="reportsLabel">Subject</div>

                  <div className="reportsValue">{subject}</div>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <div className="reportsLabel">Chapter</div>

                  <div className="reportsValue">{chapter[0]}</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              style={{
                paddingTop: 15,
              }}
            >
              <Grid container item md={3} xs={6}>
                {" "}
                <Item>
                  <div className="reportLabel">Total Queations</div>
                  <span className="reportsValues">
                    {summary.totalquestions < 9
                      ? "0" + summary.totalquestions
                      : summary.totalquestions}
                  </span>
                </Item>
              </Grid>
              <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Correct</div>
                  <span className="reportsValues">
                    {" "}
                    {summary.correct < 9
                      ? "0" + summary.correct
                      : summary.correct}
                  </span>
                </Item>
              </Grid>
              <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Incorrect</div>
                  <span className="reportsValues">
                    {" "}
                    {summary.incorrect < 9
                      ? "0" + summary.incorrect
                      : summary.incorrect}
                  </span>
                </Item>
              </Grid>
              {/* <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Marked</div>
                  <span className="reportsValues">
                    {" "}
                    {summary.marked < 9 ? "0" + summary.marked : summary.marked}
                  </span>
                </Item>
              </Grid> */}
              <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Skipped</div>
                  <span className="reportsValues">
                    {" "}
                    {summary.skipped < 9
                      ? "0" + summary.skipped
                      : summary.skipped}
                  </span>
                </Item>
              </Grid>

              <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Score</div>
                  <span className="reportsValues">
                    {" "}
                    {summary.score < 9
                      ? "0" + summary.score
                      : summary.score}{" "}
                  </span>
                </Item>
              </Grid>
              <Grid container item md={3} xs={6}>
                <Item>
                  <div className="reportLabel">Total Time (MM:SS)</div>
                  <span className="reportsValues">
                    {" "}
                    {/* {summary.totaltime < 9
                      ? "0" + summary.totaltime
                      : summary.timeConsumed} */}
                      {formatSecTommss(summary.timeConsumed)}
                  </span>
                </Item>
              </Grid>
              <Grid container item xs={12}>
                <Grid container className="cardFooter">
                  <Grid container item xs={10}>
                  <div
                      className="nextButton  report"
                      style={{ marginLeft: 15, marginTop: 5, paddingLeft:5,paddingRight:10}}
                      onClick={getQnWiseReport}
                    >
                      Question Wise Report
                    </div>
                  </Grid>
                  <Grid container item xs={2}>
                    <div
                      className="nextButton"
                      style={{ marginLeft: 15, marginTop: 5 }}
                      onClick={getOverallReport}
                    >
                      Overall Report
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container xs={2}>
            <div className="assessmentDate">{getDate()}</div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
