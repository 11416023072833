/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022\
 * Api Routes page
 * */

// Route definitions for APIs are typically stored in this section of the code,
//  and they are subsequently invoked from here.
// This organization enhances code readability and maintainability by separating
//  the routing logic from other parts of the application.

//  centralizing the route definitions in one place promotes consistency and standardization
//  across the application. It ensures that all API endpoints adhere to a unified structure and
//  naming convention, making it easier to understand and navigate the codebase.

export const routes = {
  // Authentication
  LOGIN: "login",
  GENERATE_LOGIN_OTP: "getOtp",
  GENERATE_SIGNUP_OTP: "userSignupInitial",
  VERIFY_LOGIN_OTP: "verifyOtp",
  VEIFY_SIGNUP_OTP: "verifySignupOtp",
  RESEND_OTP: "resendOTP",
  USER_SIGNUP: "userSignupFinal",
  SET_PASSWORD: "setPassword",
  UPDATE_PASSWORD: "updatePassword",
  GET_APP_TOKEN:"getAppToken",
  // User data and profile
  GET_PROFILE: "getProfile",
  GET_SYLLABUS: "getSyllabus",
  GET_CLASSES: "getClasses",
  GET_SUBJECTS: "getSubjects",
  GET_CHAPTERS: "getChapters",
  UPDATE_STUDENT_PROFILE: "updateProfile",
  // Learn On
  GET_VIDEO_TREE: "getVideoTree",
  GET_SIGNED_URL: "getSignedURL",
  GET_CONTENT_LISTING: "getContentListing",
  // Assess Me
  GET_PRACTICE_TEST: "getPracticeTest",
  SUBMIT_ANSWER: "submitAnswer",
  FINISH_EXAM: "finishExam",
  // DASHBOARD
  GET_DASHBOARD: "getDashboard",
  GET_DASHBOARD_ASSESSME: "getDashAssessme",
  // SCRATCH CARD
  VALIDATE_COUPON: "validateCoupon",
  // LIVE TUITION
  GET_COURSES: "getCourses",
  GET_COURSE_BY_ID: "getCourse",
  GET_BATCHES: "getBatches",
  GET_SESSIONS: "getSessions",
  BUY_COURSE: "buyCourse",
  MY_LIVE_TUITIONS: "myLiveTuitions",
  GET_UPCOMING_SESSIONS: "getUpcommingSessions",
  GET_COMPLETED_SESSIONS: "getCompletedSessions",
  GET_NON_PURCHASED_SESSIONS: "getNonPurchasedSessions",
  GET_PAYMENT_URL: "getPaymentUrl",
  UPDATE_FLAG: "updateFlag",
  // REPORT
  GET_REPORT: "getReport",
  // MY ORDERS
  GET_ORDERS: "getOrders",
  // RECORDINGS'
  GET_RECORDINGS_TREE: "getRecordingsTree",
  // WORKSHEETS
  GET_WORKSHEETS_LIST: "getWorkSheetsList",
  // LIVE TUITION ROOM
  GET_VIDEO_APPID: "getVideoAppId",
  GET_SESSION_BY_ID: "getSessionById",
  GET_CHATS: "getChatData?channel=",
  GET_POLLS: "getPolls?sessionID=",
  SUBMIT_ANSWER_QUIZ: "submitQuizAnswer",
  FETCH_POLL_RESULT: "getSummary?sessionId=",
};


