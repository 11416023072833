import { Grid,Radio } from "@mui/material";


export function ReportsTF(props: any) {
  function createMarkup(value: string,qn?:any) {
    if(qn){
      return {
  __html:qn+") <br />"+ value,
};
}else{
  return {
    __html: value,
  };
}
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
          item
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q,props.question.qn )}
        ></Grid>

        <Grid container item 
        className={0 === props.question.a?"optionsContainer right":!props.rightAnswer&&0 === parseInt(props.selectedanswer)?"optionsContainer wrong":"optionsContainer"}
        xs={10.4}>
          <Grid item xs={1}>
            <Radio
              style={{ marginTop: "7px" }}
              checked={ "0" === props.selectedanswer}
              value="True"
            />
          </Grid>

          <Grid
            item
            className="option"
            style={{ paddingTop: "15px", paddingLeft: "15px" }}
            xs={10.5}
          >
            True
          </Grid>
        </Grid>

        <Grid container item 
       className={1 === parseInt(props.question.a)?"optionsContainer right":!props.rightAnswer&&1 === parseInt(props.selectedanswer)?"optionsContainer wrong":"optionsContainer"}
        xs={10.4}>
          <Grid item xs={1}>
            <Radio
              style={{ marginTop: "7px" }}
        
              checked={ "1" === props.selectedanswer}
              value="False"
            />
          </Grid>

          <Grid
            item
            className="option"
            style={{ paddingTop: "15px", paddingLeft: "15px" }}
            xs={10.5}
          >
            False
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
          <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
          <span
              className="nextButton"
              style={{padding:'11px'}}
              onClick={() => props.backToReports()}
            >
              Back to Reports
            </span>
         
        </Grid>
        <Grid container item xs={1.5}>
          {props.qnIndex > 1 ? (
            <span
              className="nextButton previous"
              onClick={() => props.changeQuestion(false)}
            >
              Previous
            </span>
          ) : (
            <span className="nextButton disabled">Previous</span>
          )}
        </Grid>
        <Grid container item xs={2}>
          {props.qnIndex + 1 < props.total ? (
            <span
              className="nextButton"
              onClick={() => props.changeQuestion(true)}
            >
              Next
            </span>
          ) : (
            <span className="nextButton disabled">Next</span>
          )}
        </Grid>
      </Grid>
    </>
  );
}
