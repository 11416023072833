import { Checkbox, FormControlLabel, Grid } from "@mui/material";
import { constants } from "../../../../constants/constants";
import { useState } from "react";

export function ReportsMCQComponent(props: any) {
  function createMarkup(value: string, qn?:any) {
    if(qn){
          return {
      __html:qn+") <br />"+ value,
    };
    }else{
      return {
        __html: value,
      };
    }

  }
  console.log(props.question);
  return (
    <>
     
      <Grid container style={{ paddingLeft: "10vw" }}>

        <Grid
          item
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q, props.question.qn)}
        >
          {/* {props.question.q} */}
        </Grid>
        {props.question.o.map((option: any, index: number) => {
          return (
            <Grid
              container
              key={index}
              className={
                index == props.question.a
                  ? "optionsContainer right"
                  : !props.rightAnswer && index == props.selectedanswer
                  ? "optionsContainer wrong"
                  : "optionsContainer"
              }
              item
              xs={11}
              md={10.4}
            >
              <Grid item xs={2} md={1}>
                <Checkbox
                  style={{ marginTop: "7px" }}
                  id={constants.APLPHABETS[index]}
                  name={JSON.stringify(index)}
                  checked={
                    !props.skipped && index === parseInt(props.selectedanswer)
                  }
                />
              </Grid>
              <Grid item className="optionsLabel" xs={1} md={0.5}>
                {constants.APLPHABETS[index]} &nbsp;
              </Grid>
              <Grid
                item
                className="mcqOption"
                dangerouslySetInnerHTML={createMarkup(option, false)}
                xs={9}
                md={10.5}
              ></Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <span
              className="nextButton"
              style={{padding:'11px'}}
              onClick={() => props.backToReports()}
            >
              Back to Reports
            </span>
        </Grid>

        <Grid container item xs={1.5}>
          {props.qnIndex > 1 ? (
            <span
              className="nextButton previous"
              onClick={() => props.changeQuestion(false)}
            >
              Previous
            </span>
          ) : (
            <span className="nextButton disabled">Previous</span>
          )}
        </Grid>
        <Grid container item xs={2}>
          {props.qnIndex + 1 < props.total ? (
            <span
              className="nextButton"
              onClick={() => props.changeQuestion(true)}
            >
              Next
            </span>
          ) : (
            <span className="nextButton disabled">Next</span>
          )}
        </Grid>
      </Grid>
    </>
  );
}
