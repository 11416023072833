import { Checkbox, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { constants } from "../../../../constants/constants";
import "../../Reports.scss"
export function ReportsMTF(props: any) {
  function createMarkup(value: string,qn?:any) {
    if(qn){
      return {
  __html:qn+") <br />"+ value,
};
}else{
  return {
    __html: value,
  };
}
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "6vw" }}>
        <Grid
          item
          xs={12}
          className="question hideScrollBar"
          dangerouslySetInnerHTML={createMarkup(props.question.q,props.question.qn )}
        >
          {/* {props.question.q} */}
        </Grid>
        <Grid className="mtfOptionsContainer" xs={12}>
          {/* <Grid xs={0} md={1} className="" item></Grid> */}
          <Grid xs={6} md={4} item>
            {props.question.o.map((option: any) => {
              return (
                <Grid item container xs={12} key={option.c1}>
                  <Grid item xs={2} className="mtfQuestionOptions pt">
                    {constants.APLPHABETS[option.SiNo - 1]}
                  </Grid>
                  <Grid
                    className="mtfQuestionOptions hideScrollBar"
                    dangerouslySetInnerHTML={createMarkup(option.c1)}
                    item
                    xs={10}
                    // value={option.c1}
                    // onChange={handleChange("syllabus")}
                  ></Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid xs={0} md={1} className="" item>
            {props.question.o.map((option: any) => {
              return <Grid item xs={12} className="" key={option.c1}></Grid>;
            })}
          </Grid>

          <Grid xs={6} md={4} item>
            {props.question.o.map((option: any, index: number) => {
              return (
                <Grid
                  item
                  container
                  xs={12}
                  className="mtfQuestionOptions select"
                  key={option.SiNo}
                >
                  <Grid
                    item
                    xs={2}
                    className={
                      props.selectedanswer &&props.selectedanswer[option.SiNo - 1] ===
                      props.question.a[option.SiNo - 1]
                        ? "mtfQuestionOptions pt right"
                        : "mtfQuestionOptions pt wrong"
                    }
                  >
                    {props.selectedanswer?props.selectedanswer[option.SiNo - 1]:""}
                  </Grid>
                  <Grid
                    className="mtfQuestionOptions hideScrollBar"
                    dangerouslySetInnerHTML={createMarkup(option.c2)}
                    item
                    xs={8}

                    // value={option.c1}
                    // onChange={handleChange("syllabus")}
                  ></Grid>
                  <Grid item xs={2} className="mtfQuestionOptions pt">
                    {props.question.a[option.SiNo - 1]}
                  </Grid>
                  {/* <TextField
                  // className="mtfOptions"
                  style={{ width: "100%" }}
                  id="outlined-select-currency"
                  select
                  label="Choose your Answer"
                  value={option}
                  // onChange={handleChange("syllabus")}
                >
                  {optionsArray.map((optionAns) => (
                    <MenuItem dangerouslySetInnerHTML={createMarkup(optionAns)} onClick={()=>{selectAnswer(optionAns, index)}} value={optionAns} key={optionAns}>
                    </MenuItem>
                  ))}
                  {["hello", "hai", "what"].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))} 
                </TextField> */}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <span
              className="nextButton"
              style={{padding:'11px'}}
              onClick={() => props.backToReports()}
            >
              Back to Reports
            </span>
        </Grid>

        <Grid container item xs={1.5}>
          {props.qnIndex > 1 ? (
            <span
              className="nextButton previous"
              onClick={() => props.changeQuestion(false)}
            >
              Previous
            </span>
          ) : (
            <span className="nextButton disabled">Previous</span>
          )}
        </Grid>
        <Grid container item xs={2}>
          {props.qnIndex + 1 < props.total ? (
            <span
              className="nextButton"
              onClick={() => props.changeQuestion(true)}
            >
              Next
            </span>
          ) : (
            <span className="nextButton disabled">Next</span>
          )}
        </Grid>
      </Grid>
    </>
  );
}
