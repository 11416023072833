/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna
 * Layout page...
 * The router outlet is called here.
 */
import React from "react";
import { Outlet } from "react-router-dom";
import "./Layout.scss";

// Layout component starts here
const Layout = () => {
  return <Outlet />;
};

export default Layout;
