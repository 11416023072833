/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 * Settins page
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Grid } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useNavigate } from "react-router-dom";
import { ReactComponent as FabRightArrow } from "../../assets/icons/fabRightArrow.svg";

import "./Settings.scss";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Settings page function starts here */
export function SettingsPage() {
  const navigate = useNavigate();
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
    profile_image:""
  });
  navigateTo('/dashboard')
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
     
    } else {
      navigateTo("/login");
    }
  };
  useEffect(() => {
    validateLoggedIn();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  function navigateTo(path: string) {
    navigate(path);
  }
  return (
    <Grid container style={{ width: "100vw" }} className="fade-in-animate">
         <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        />
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={'settings'}/>
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <div className="settingsContainer">
          <div className="settingsProfileImageContainer"></div>
          <span className="profileName">Hello!</span>
          <br />
          <span className="listLabel">Manage your account settings</span>

          <div className="settingsCardContaianer hideScrollBar">
            <Grid className="settingsLayoutCardContainer" style={{minWidth:"350px"}}>
              <Grid container>
                <Grid
                  container
                  item
                  xs={10}
                  onClick={() => navigateTo("/settings/profile")}
                >
                  <div>
                    <span className="settingMenuContainerHeader">Profile</span>
                    <br />
                    <span className="settingMenuContainerLabel">
                      Hi learner! Here’s your student profile
                    </span>
                  </div>
                </Grid>

                <Grid
                  container
                  item
                  xs={2}
                  onClick={() => navigateTo("/settings/profile")}
                >
                  <div className="">
                    <FabRightArrow />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="settingsLayoutCardContainer" style={{minWidth:"350px"}}>
              <Grid container>
                <Grid container item xs={10}>
                  <div>
                    <span className="settingMenuContainerHeader">
                      My orders
                    </span>
                    <br />
                    <span className="settingMenuContainerLabel">
                      See your orders
                    </span>
                  </div>
                </Grid>

                <Grid container item xs={2}>
                  <div className="">
                    <FabRightArrow />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid className="settingsLayoutCardContainer" style={{minWidth:"350px"}}>
              <Grid container>
                <Grid
                  container
                  item
                  xs={10}
                  onClick={() => navigateTo("/settings/password")}
                >
                  <div>
                    <span className="settingMenuContainerHeader">Password</span>
                    <br />
                    <span className="settingMenuContainerLabel">
                      Want a change? Modify your existing password
                    </span>
                  </div>
                </Grid>

                <Grid
                  container
                  item
                  xs={2}
                  onClick={() => navigateTo("/settings/password")}
                >
                  <div className="">
                    <FabRightArrow />
                  </div>
                </Grid>
              </Grid>
            </Grid>{" "}
            {/* <Grid className="settingsLayoutCardContainer" style={{minWidth:"350px"}}>
              <Grid container>
                <Grid container item xs={10}>
                  <div>
                    <span className="settingMenuContainerHeader">Learners</span>
                    <br />
                    <span className="settingMenuContainerLabel">
                      Where you can edit your profile
                    </span>
                  </div>
                </Grid>

                <Grid container item xs={2}>
                  <div className="">
                    <FabRightArrow />
                  </div>
                </Grid>
              </Grid>
            </Grid> */}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
