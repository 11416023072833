/*
* messages.ts
* 
* @author Rahul krishna.
* @date 01/11/2022.
* @version 1.0.0

=> All the Messages that are used in the app should be added here and used from here.
*/
export const messageUtil = {
  VALID_PHONE_NUMBER: "Enter a valid phone number",
  VALID_PASSWORD: "Enter a valid password",
  ENTER_SYLLABUS:"Select a valid syllabus",
  ENTER_STANDARD:"Select a valid Classs",
  ENTER_CITY:"Enter your city",
  ENTER_CURRENT_PASSWORD: "Enter your Current Password",
  PWD_NOT_MATCHING: "Passwords does not match",
  NEW_AND_OLD_PWD_SAME: "New password cannot be current password",
  VALID_EMAIL: "Enter a valid E-mail",
  VALID_NAME: "Name is reqired!",
  PROFILE_UPDATE_SUCCESFULL:"Profile updated successfully!",
  CLASS_UPDATE_SUCCESS:"Class Upgraded  Successfully! Thank you for enhancing the class",
  SOMETHING_WENT_WRONG:
    "Something went wrong! Please check your network or try again",
  SOMETHING_WENT_WRONG_OS: "Something went wrong on our side! Please try again",
  FETCH_SYLLABUS_FAILED:
    "Something went wrong! couldn't fetch syllabyus, Please check your network connection",
  OTP_SENT: "OTP has been sent",
  OTP_SENT_CLASS_UPGRADE:"An OTP has been sent to your registered mobile number",
  SIGNUP_SUCCESFULL: "Sign up successfull!",
  SESSION_EXPIRED: "Session expired! Please login again",
  WAITING_MESSAGE_FOR_LOADER: "Its taking longer than usual, please wait...",
  LOGIN_AGAIN : "Congratulations! You have successfully signed up to doubtbox! please login with the credentials",
  NO_SUBJECTS: "No subjects found!",
  NO_CHAPTERS: "No chapters found!",
  ASSESSME_FETCH_FAILED:"Failed to fetch assessment!",
  ANSWER_SUBMISSION_FAILED:"Submission failed.. Please try again",
  FINISH_EXAM_FAILED:"Attempt to Finish exam Failed",
  OTP_LIMIT_EXCEEDED:"Total number of tries exceeded!",
  WRONG_OTP:"Incorrect OTP"
};
