import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import "./assessMeComponents.scss";
import { constants } from "../../../constants/constants";
import { useEffect, useState } from "react";
import { getFromLocalStorage } from "../../../shared/utils/utilityFunctions";

export default function TFComponent(props: {
  markAnswer: any;
  question: any;
  nextQuestion: any;
  prevQuestion: any;
  answeredData:any;
  currQnindex:any;
  qnStartTime:any;

}) {
  var [skipped, setSkipped] = useState(false);
  var [marked, setMarkForReview] = useState(false);

  useEffect(() => {
    setSelectedValue("");
  }, [props.question.q]);

  useEffect(()=>{

    console.log(props.answeredData[props.currQnindex]);
    let currQn = props.answeredData[props.currQnindex];
    setMarkForReview(currQn.marked?true : false);

    if (currQn && currQn.answer&& !currQn.answer.skipped) {
      console.log("ansered aan");
      setSelectedValue(currQn.answer.selectedanswer === "0"?"True":"False")
    }

  },[props.question])

  function createMarkup(value: string) {
    return {
      __html: value,
    };
  }
  const [selectedValue, setSelectedValue] = useState("");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  function changeQuestion(param: boolean) {
    // param true = next question
    // param false = previous question
    if (param) {
      let packageId = getFromLocalStorage("packageId");

      let apiData = {
        answer: {
          question_id: props.question.content_uuid,
          totaltime: 0,
          package_id: packageId,
          question_type: "TrueORFalse",
          skipped: selectedValue ===""?true:false,
          selectedanswer:
            selectedValue === "True"
              ? "0"
              : selectedValue === "False"
              ? "1"
              : undefined,
        },
      };
      console.log(apiData)
      props.nextQuestion(apiData, marked);
    } else {
      props.prevQuestion(); 
    }
  }
  const markForReview = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: any
  ) => {
    console.log(event.target.value, id);
    setMarkForReview(marked => !marked)
    console.log(marked)
  };
  function clear(){
    setSelectedValue("")
  }
  return (
    <>
      <Grid container style={{ paddingLeft: "10vw" }}>
        <Grid
        item
          xs={10.4}
          className="question"
          dangerouslySetInnerHTML={createMarkup(props.question.q)}
        >
        </Grid>

        <Grid container item className="optionsContainer" xs={10.4}>
          <Grid item xs={1}>
            <Radio
              style={{ marginTop: "7px" }}
              onChange={handleChange}
              checked={selectedValue === "True"}
              value="True"
            />
          </Grid>

          <Grid
            item
            className="option"
            style={{ paddingTop: "15px", paddingLeft:"15px" }}
            xs={10.5}
          >
            True
          </Grid>
        </Grid>

        <Grid container item className="optionsContainer" xs={10.4}>
          <Grid item xs={1}>
            <Radio
              style={{ marginTop: "7px" }}
              onChange={handleChange}
              checked={selectedValue === "False"}
              value="False"
            />
          </Grid>

          <Grid
            item
            className="option"
            style={{ paddingTop: "15px",paddingLeft:"15px"  }}
            xs={10.5}
          >
            False
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ paddingLeft: "25px", marginTop: "5vh" }}
      >
        <Grid item xs={5} style={{ paddingLeft: "8vw" }}>
        <Checkbox
            onChange={(e) => {
              markForReview(e, props.question);
            }}
            checked={marked}
            defaultChecked={marked}
          />
          <span className="questionNo">
            {" "}
            Mark for review 
          </span>
         
        </Grid>
        <Grid container item xs={2.5}>
        <span onClick={()=> clear()} className="clearButton">Clear</span>

        </Grid>
        <Grid container item xs={1.5}>
          <span
            className="nextButton previous"
            onClick={() => changeQuestion(false)}
          >
            Previous
          </span>
        </Grid>
        <Grid container item xs={2}>
          <span className="nextButton" onClick={() => changeQuestion(true)}>
            Next
          </span>
        </Grid>
      </Grid>
    </>
  );
}
