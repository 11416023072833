/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Assess me overall reports page.
 */
import { Grid, styled } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import { constants } from "../../../constants/constants";
import LoaderComponent from "../../../shared/loader/loader";
import Navbar from "../../../shared/Navbar";
import {
  getDate,
  getFromLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { ReportsMCQComponent } from "./components/ReportsMCQ";
import { ReportsFIB } from "./components/ReportsFIB";
import { ReportsTF } from "./components/ReportsTF";
import { ReportsMTF } from "./components/ReportsMTF";
import { ReportsOWQ } from "./components/ReportsOWQ";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} size="116px" />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          fontSize={30}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#0E2744" : "#ffff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  width: "100%",
  textAlign: "left",
  color: theme.palette.mode === "dark" ? "#fff" : "#3E5269",
  border: "2px solid #D8DCE1",
  margin: "10px",
  borderRadius: "8px",
}));

const getxsValue = (
  isfor: string,
  windowFlag: boolean,
  hideNavBar: boolean
) => {
  if (hideNavBar) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return 12;
    }
  }
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export function AssessMeQnReportPage() {
  const navigate = useNavigate();
  const reportData = getFromLocalStorage("assessMeResult");
  const result = reportData.Result ? reportData.Result : [];
  console.log(result);
  const [profileData, setProfileData] = useState({
    class: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
  });
  const [loader, setLoader] = useState(false);
  const [currQn, setCurrQn] = useState<any>(result ? result[0] : {});
  const [qnIndex, setQnIndex] = useState(1);

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;

  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
      if (reportData.length === 0) navigateTo("/dashboard");
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path: string) {
    navigate(path);
  }
  function changeQuestion(action: boolean) {
    console.log(qnIndex, result.length);

    if (action) {
      if (qnIndex + 1 < result.length) {
        setQnIndex(qnIndex + 1);
        setCurrQn(result[qnIndex + 1]);
      }
    } else {
      if (qnIndex + 1 > 1) {
        setQnIndex(qnIndex - 1);
        setCurrQn(result[qnIndex - 1]);
      }
    }
  }
  function backToReports(){
    navigateTo("/reports/assess-me-report");
  }
  return (
    <Grid container>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"assess-me"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)} style={{marginTop:25}}>
        {/* <TopBarComponent
          logo={false}
          search={false}
          profileData={profileData}
        /> */}

        {currQn.question.type === constants.QUESTION_TYPES_REFER.MCQ && (
          <ReportsMCQComponent
            question={currQn.question}
            rightAnswer={currQn.RightAnswer}
            selectedanswer={currQn.selectedanswer}
            changeQuestion={changeQuestion}
            backToReports={backToReports}
            qnIndex={qnIndex}
            skipped={currQn.skipped}
            total={result.length}
          />
        )}
        {currQn.question.type ===
          constants.QUESTION_TYPES_REFER.FillInTheBlanks && (
          <ReportsFIB
            question={currQn.question}
            rightAnswer={currQn.RightAnswer}
            selectedanswer={currQn.selectedanswer}
            changeQuestion={changeQuestion}
            backToReports={backToReports}
            qnIndex={qnIndex}
            total={result.length}
          />
        )}
        {currQn.question.type ===
          constants.QUESTION_TYPES_REFER.MatchTheFollowing && (
          <ReportsMTF
            question={currQn.question}
            rightAnswer={currQn.RightAnswer}
            selectedanswer={currQn.selectedanswer}
            changeQuestion={changeQuestion}
            backToReports={backToReports}
            qnIndex={qnIndex}
            total={result.length}
          />
        )}
        {currQn.question.type ===
          constants.QUESTION_TYPES_REFER.OneWordQuestion && (
          <ReportsOWQ
            question={currQn.question}
            rightAnswer={currQn.RightAnswer}
            selectedanswer={currQn.selectedanswer}
            changeQuestion={changeQuestion}
            backToReports={backToReports}
            qnIndex={qnIndex}
            total={result.length}
          />
        )}
        {currQn.question.type ===
          constants.QUESTION_TYPES_REFER.TrueORFalse && (
          <ReportsTF
            question={currQn.question}
            rightAnswer={currQn.RightAnswer}
            selectedanswer={currQn.selectedanswer}
            changeQuestion={changeQuestion}
            backToReports={backToReports}
            qnIndex={qnIndex}
            skipped={currQn.skipped}
            total={result.length}
          />
        )}
      </Grid>
    </Grid>
  );
}
