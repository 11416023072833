import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import GradingIcon from "@mui/icons-material/Grading";
import LockIcon from "@mui/icons-material/Lock";
import UpgradeIcon from "@mui/icons-material/Upgrade";
export function ProfileSettingsSubmenu(props: { show: boolean,navigateTo:Function }) {
  return (
    <Collapse in={props.show} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 3 }} onClick={()=>{props.navigateTo('/settings/profile')}}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>
      </List>
    </Collapse>
  );
}

export function PurchaseSettingsSubmenu(props: { show: boolean,navigateTo:Function  }) {
  return (
    <Collapse in={props.show} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 3 }} onClick={()=>{props.navigateTo('/my-orders')}}>
          <ListItemIcon >
            <GradingIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="My Orders" />
        </ListItemButton>
      </List>
    </Collapse>
  );
}

export function SettingsSubmenu(props: { show: boolean,navigateTo:Function  }) {
  return (
    <Collapse in={props.show} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 3 }} onClick={()=>{props.navigateTo('/settings/password')}}>
          <ListItemIcon>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Password Settings" />
        </ListItemButton>
        <ListItemButton sx={{ pl: 3 }} onClick={()=>{props.navigateTo('/settings/class-upgrade')}}>
          <ListItemIcon>
            <UpgradeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Class Upgrade" />
        </ListItemButton>
      </List>
    </Collapse>
  );
}
