import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import React, { useEffect, useState } from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { ReactComponent as FabRightArrow } from "../../../assets/icons/fabRightArrow.svg";
import { useNavigate } from "react-router-dom";
import { messageUtil } from "../../../shared/utils/messages";
import { services } from "../../../services/api_service";
import { Md5 } from "ts-md5";
import AlertService from "../../../shared/alert/alertComponent";
import LoaderComponent from "../../../shared/loader/loader";
import { constants } from "../../../constants/constants";
import swal from "sweetalert";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordState {
  currentPassword: string;
  newPassword: string;
  reEnterPassword: string;
}
export function SettingsPasswordPage() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [userData, setUserData] = useState({
    mobile: "",
    hasPass: false,
  });
  const [pwdNotSame, setPwdNotSameErr] = useState(false);
  const [loader, setLoader] = useState(false);

  const [passwordExists, setpasswordExists] = useState(false);
  const [values, setValues] = React.useState<PasswordState>({
    currentPassword: "",
    newPassword: "",
    reEnterPassword: "",
  });
  const [errors, setErrors] = useState({
    newPwd: { value: false, message: "" },
    rePwd: { value: false, message: "" },
    currentPassword: { value: false, message: "" },
  });
  useEffect(() => {
    const validateLoggedIn = () => {
      var val = localStorage.getItem("profileData");
      var userData = localStorage.getItem("userData");

      if (typeof val !== "undefined" && val !== null)
        setProfileData(JSON.parse(val));
      else {
        navigateTo("/login");
      }

      // if (typeof userData !== "undefined" && userData !== null)
      //   setUserData(JSON.parse(userData));
    };
    validateLoggedIn();
    setpasswordExists(userData.hasPass ? userData.hasPass : false);
    console.log(userData);
  }, [userData.hasPass]);

  useEffect(() => {
    var userData = localStorage.getItem("userData");
    if (typeof userData !== "undefined" && userData !== null)
      setUserData(JSON.parse(userData));
  }, []);
  const handleChange =
    (prop: keyof PasswordState) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (prop === "currentPassword") {
        setErrors({
          ...errors,
          currentPassword: { value: false, message: "" },
        });
      }
      if (prop === "reEnterPassword") {
        if (event.target.value !== values.newPassword) {
          setPwdNotSameErr(true);
        } else {
          setPwdNotSameErr(false);
        }
        setErrors({ ...errors, rePwd: { value: false, message: "" } });
      }
      if (prop === "newPassword") {
        console.log(errors);
        if (
          values.reEnterPassword &&
          event.target.value !== values.reEnterPassword
        ) {
          setPwdNotSameErr(true);
        } else if (!values.reEnterPassword && !values.newPassword) {
          setPwdNotSameErr(false);
        } else {
          setPwdNotSameErr(false);
        }
        setErrors({ ...errors, newPwd: { value: false, message: "" } });
      }
      setValues({ ...values, [prop]: event.target.value });
      console.log(prop);
    };

  function navigateTo(path: string) {
    navigate(path);
  }
  function setPwd(update?: false) {
    let err = 0;
    console.log(values, update);
    if (!values.reEnterPassword) {
      setErrors({
        ...errors,
        rePwd: { value: true, message: messageUtil.VALID_PASSWORD },
      });
      err++;
    }
    if (!values.newPassword) {
      setErrors({
        ...errors,
        newPwd: { value: true, message: messageUtil.VALID_PASSWORD },
      });
      err++;
    }

    if (!values.currentPassword && update) {
      setErrors({
        ...errors,
        currentPassword: {
          value: true,
          message: messageUtil.ENTER_CURRENT_PASSWORD,
        },
      });
      err++;
    }

    if (err === 3) {
      if (update) {
        setErrors({
          rePwd: { value: true, message: messageUtil.VALID_PASSWORD },
          newPwd: { value: true, message: messageUtil.VALID_PASSWORD },
          currentPassword: {
            value: true,
            message: messageUtil.ENTER_CURRENT_PASSWORD,
          },
        });
      } else {
        setErrors({
          rePwd: { value: true, message: messageUtil.VALID_PASSWORD },
          newPwd: { value: true, message: messageUtil.VALID_PASSWORD },
          currentPassword: { value: false, message: "" },
        });
      }
    }
    if (values.newPassword !== values.reEnterPassword) {
      err++;
    }
    // if (values.newPassword.length < constants.PASSWORD_LENGTH) {
    //   setErrors({
    //     ...errors,
    //     newPwd: { value: true, message: "password" },
    //   });
    //   err++;
    // }

    // if (
    //   update &&
    //   values.currentPassword.length > 0 &&
    //   values.currentPassword === values.newPassword
    // ) {
    //   err++;
    //   setErrors({
    //     rePwd: { value: true, message: "" },
    //     newPwd: { value: true, message: messageUtil.NEW_AND_OLD_PWD_SAME },
    //     currentPassword: { value: true, message: "" },
    //   });
    // }
    console.log(err);
    if (err !== 0) return;
    setLoader(true);
    if (update) {
      console.log(userData);
      let apiData = {
        mobile: userData.mobile,
        currentPassword: Md5.hashStr(values.currentPassword),
        newPassword: Md5.hashStr(values.newPassword),
      };
      services.updatePassword(apiData).then((res) => {
        setLoader(false);
        console.log(res);
        console.log(res.message);
        if (
          res &&
          res.message &&
          res.message === "Password updated successfully!"
        ) {
          // showAlert("success", res.message);
          swal({
            title: res.message,
            icon: "success",
          });
          navigateTo("/dashboard");
        } else showAlert("error", res.message);
      });
      console.log(apiData);
    } else {
      let apiData = {
        mobile: userData.mobile,
        password: Md5.hashStr(values.newPassword),
      };
      console.log(apiData, userData);
      services.setPassword(apiData).then((res) => {
        console.log(res);
        if (res && res.message === "Password set Successfully!") {
          setLoader(false);

          showAlert("success", res.message);
          setTimeout(() => {
            navigateTo("/dashboard");
          }, 3000);
        } else showAlert("error", res.message);
      });
    }
  }

  // for alert messaging
  const [errorType, setErrorType] = useState("error");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertShow(false);
  };

  function showAlert(errorType: string, message: string) {
    setErrorType(errorType);
    setNotificationMessage(message);
    setAlertShow(true);
  }

  return (
    <>
      <AlertService
        alertShow={alertShow}
        setAlertShow={setAlertShow}
        errorType={errorType}
        notificationMessage={notificationMessage}
        handleClose={handleCloseAlert}
      />
      {loader && <LoaderComponent />}
      <div className="SettingsProfileContainer fade-in-animate">
        <TopBarComponent search={false} logo={true} profileData={profileData} />
        <div className="backToSettings" onClick={() => navigateTo("/dashboard")}>
          <FabRightArrow className="invertFavArrow" />
          <span className="backToSettingslabel">Dashboard</span>
        </div>
        <div className="settingsContainer subContainer pd-t-50 hideScrollBar">
          {/* if password is not present for user, show create password */}
          {/* if password is present for user, show change password */}
          {passwordExists ? (
            <ChangePassword
              values={values}
              handleChange={handleChange}
              navigateTo={navigateTo}
              pwdNotSame={pwdNotSame}
              setPwd={setPwd}
              errors={errors}
            />
          ) : (
            <CreatePassword
              values={values}
              handleChange={handleChange}
              navigateTo={navigateTo}
              pwdNotSame={pwdNotSame}
              setPwd={setPwd}
              errors={errors}
            />
          )}
        </div>
      </div>
    </>
  );
}

function ChangePassword(props: {
  values: any;
  handleChange: any;
  navigateTo: any;
  errors: any;
  pwdNotSame: boolean;
  setPwd: any;
}) {
  const [showCurrentPassword, setCurrentShowPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const handleClickShowPassword = (input: string) => {
    switch (input) {
      case "currentPassword":
        setCurrentShowPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "rePassword":
        setShowRePassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Grid className="subSettingsContainer" container item xs={12} sm={8} md={4}>
      <div>
        <span className="settingMenuContainerHeader">Change Password</span>
        <br />
        <span className="listLabel">
          Want a change? Modify your existing password
        </span>
      </div>
      {/* <div className="settingsCardContaianer subSettingsOptionsContainer"> */}
      <Grid
        className="settingsCardContaianer subSettingsOptionsContainer"
        container
        item
        xs={12}
        sm={12}
        md={12}
      >
        {/* <div className="settingsProfileImageContainer"></div> */}
        <div className="inputContainer">
          <TextField
            className="nameInput"
            id="outlined-adornment-passsword"
            label="Current Password"
            value={props.values.currentPassword}
            error={props.errors.currentPassword.value}
            onChange={props.handleChange("currentPassword")}
            type={showCurrentPassword ? "text" : "password"}
          ></TextField>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword("currentPassword")}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          {/* <OutlinedInput
            // id="outlined-adornment-password"
            className="nameInput"
            type={showPassword ? 'text' : 'password'}
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Current Password"
          /> */}
        </div>
        <div>
          {props.errors.currentPassword.value === true ? (
            <Grid item xs={12} className="inputContainer">
              <span style={{ color: "red" }}>
                {props.errors.currentPassword.message}
              </span>
            </Grid>
          ) : null}
        </div>

        <div className="inputContainer">
          <TextField
            className="nameInput"
            id="outlined-adornment-currency"
            label="New Password"
            value={props.values.newPassword}
            onChange={props.handleChange("newPassword")}
            type={showNewPassword ? "text" : "password"}
            error={props.errors.newPwd.value}
          ></TextField>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword("newPassword")}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>

        <div>
          {props.errors.newPwd.value === true ? (
            <Grid item xs={12} className="inputContainer">
              <span style={{ color: "red" }}>
                {props.errors.newPwd.message}
              </span>
            </Grid>
          ) : null}
        </div>
        <div className="inputContainer">
          <TextField
            className="nameInput"
            id="outlined-adornment-currency"
            label="Re-enter Password"
            value={props.values.reEnterPassword}
            onChange={props.handleChange("reEnterPassword")}
            type={showRePassword ? "text" : "password"}
            error={props.errors.rePwd.value}
          ></TextField>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword("rePassword")}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showRePassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
        {props.errors.rePwd.value === true ? (
          <div style={{ color: "red" }}>{props.errors.rePwd.message}</div>
        ) : props.pwdNotSame === true ? (
          <div style={{ color: "red" }}>{messageUtil.PWD_NOT_MATCHING}</div>
        ) : null}

        <div className="settingsSubFooter">
          <Button
            variant="text"
            className="settingsCancelButton"
            onClick={() => props.navigateTo("/settings")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="settingsSaveButton"
            onClick={() => props.setPwd(true)}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}

function CreatePassword(props: {
  values: any;
  handleChange: any;
  navigateTo: any;
  pwdNotSame: any;
  setPwd: any;
  errors: any;
}) {
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);

  const handleClickShowPassword = (input: string) => {
    switch (input) {
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "rePassword":
        setShowRePassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <Grid className="subSettingsContainer" container item xs={12} sm={8} md={4}>
      <div>
        <span className="settingMenuContainerHeader">Create New Password</span>
        <br />
        <span className="listLabel">
          Want a change? Create new password to log in with
        </span>
      </div>
      {/* <div className="settingsCardContaianer subSettingsOptionsContainer"> */}
      <Grid
        className="settingsCardContaianer subSettingsOptionsContainer"
        container
        item
        xs={12}
        sm={12}
        md={12}
      >
        {/* <div className="settingsProfileImageContainer"></div> */}

        <div className="inputContainer">
          <TextField
            className="nameInput"
            id="outlined-adornment-password"
            label="New Password"
            value={props.values.newPassword}
            onChange={props.handleChange("newPassword")}
            type={showNewPassword?"text":"password"}
            error={props.errors.newPwd.value}
          ></TextField>
            <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword("newPassword")}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showNewPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>

        <div>
          {props.errors.newPwd.value === true ? (
            <Grid item xs={12} className="inputContainer">
              <span style={{ color: "red" }}>
                {props.errors.newPwd.message}
              </span>
            </Grid>
          ) : null}
        </div>
        <div className="inputContainer">
          <TextField
            className="nameInput"
            id="outlined-adornment-re-password"
            label="Re-enter Password"
            value={props.values.reEnterPassword}
            onChange={props.handleChange("reEnterPassword")}
            type={showRePassword?"text":"password"}
            error={props.errors.rePwd.value}
          ></TextField>
            <IconButton
            aria-label="toggle password visibility"
            onClick={() => handleClickShowPassword("rePassword")}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showRePassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>
        {props.errors.rePwd.value === true ? (
          <div style={{ color: "red" }}>{props.errors.rePwd.message}</div>
        ) : props.pwdNotSame === true ? (
          <div style={{ color: "red" }}>{messageUtil.PWD_NOT_MATCHING}</div>
        ) : null}

        <div className="settingsSubFooter">
          <Button
            variant="text"
            className="settingsCancelButton"
            onClick={() => props.navigateTo("/settings")}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            className="settingsSaveButton"
            onClick={() => props.setPwd(false)}
          >
            Save
          </Button>
        </div>
      </Grid>
    </Grid>
  );
}
