export const configurations = {
  SYLLABUS_LIST: [
    {
      id: 1,
      name: "CBSE",
    },
    {
      id: 2,
      name: "ICSE",
    },
    {
      id: 3,
      name: "Kerala State",
    },
  ],
  // This Hardcoded data returns the original list of classess from getClass api for the
  // syllabus id 1 (CBSE) This may cause some unexpected problems down the line and
  // The management is aware of this and are willing to take the risk.
  CLASS_LIST: [
    {
      id: 1,
      name: "Class 1",
    },
    {
      id: 2,
      name: "Class 2",
    },
    {
      id: 3,
      name: "Class 3",
    },
    {
      id: 4,
      name: "Class 4",
    },
    {
      id: 5,
      name: "Class 5",
    },
    {
      id: 6,
      name: "Class 6",
    },
    {
      id: 7,
      name: "Class 7",
    },
    {
      id: 8,
      name: "Class 8",
    },
    {
      id: 9,
      name: "Class 9",
    },
    {
      id: 10,
      name: "Class 10",
    },
    {
      id: 11,
      name: "Class 11",
    },
    {
      id: 12,
      name: "Class 12",
    },
  ],
};
