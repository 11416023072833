/**
 * Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * @Author Rahul Krishna
 * Search page.
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../constants/constants";
import { Button, Grid, Typography } from "@mui/material";
import Navbar from "../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";

import { services } from "../../services/api_service";
import { TopBarComponent } from "../../components/topBarComponent/topBarComponent";
import LoaderComponent from "../../shared/loader/loader";
// import { templivetuitionData } from "../../constants/tempDatas";
import { LiveTuitionCard } from "../../components/uiComponents/uiComponents";

const getxsValue = (isfor: string, windowFlag: boolean) => {
  if (windowFlag) {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_TRUE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_TRUE_OUTLET;
    }
  } else {
    if (isfor === "navbar") {
      return constants.XS_VALUES.WINOW_FLAG_FALSE_NAVBAR;
    } else {
      return constants.XS_VALUES.WINDOW_FLAG_FALSE_OUTLET;
    }
  }
};

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

/** Actual Search page function starts here */
export function SearchPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateProps, setTree] = useState(location.state);
  console.log(stateProps);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    standard: "",
    syllabus: "",
    userName: "",
    className: "",
    syllabusName: "",
  });

  const [report, setReport] = useState([{}]);
  const [topicData, setTopicData] = useState([{ QID: "" }]);

  const [loader, setLoader] = useState(false);
  const [stage, setStage] = useState("default");

  useEffect(() => {
    validateLoggedIn();

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null) {
      setProfileData(JSON.parse(val));
    } else {
      navigateTo("/login");
    }
  };
  function navigateTo(path:any,state?: any) {
    navigate(path, { state: state });
  }
  useEffect(() => {
    function fetchReport() {}
    if (profileData.userName) fetchReport();
  }, [profileData.userName]);

  function moreDetails(one: any) {
    console.log(one);
    setTopicData(one);
    setStage("overallPerformance");
  }

  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [selected, setSelected] = React.useState([]);

  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };
  const handleOpen = (courseId?: any) => {
    //  setOpen(true);
    console.log(courseId);
    let state = {
      courseId: courseId,
    };

    navigateTo("/live-tuition/details", state);
  };
  return (
    <Grid container style={{ width: "100vw" }}>
      {loader && <LoaderComponent />}
      <Grid item xs={getxsValue("navbar", windowFlag)}>
        <Navbar path={"search"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag)}>
        <TopBarComponent
          logo={false}
          search={true}
          profileData={profileData}
        />

        <Grid
          container
          spacing={2}
          item
          xs={12}
          className=""
          style={{ marginTop: "20px" }}
        >
          <Grid xs={10} item>
            Showing Results for '<i>{stateProps.search}</i>'
          </Grid>
          <Grid xs={2}>
            <Button
              variant="contained"
              sx={{
                background: "#0E2744",
                width: "112px",
                marginBottom: "15px",
              }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </Grid>
          <Grid xs={12} container item>
            {/* {templivetuitionData.map((obj: any) => (
              <LiveTuitionCard
                itemId={obj.TransactionId} // NOTE: itemId is required for track items
                title={obj.topic_name}
                key={obj.TransactionId}
                onClick={handleClick(obj.TransactionId)}
                selected={isItemSelected(obj.TransactionId)}
                button={"More Details"}
                handleOpen={handleOpen}
                handleOpenTeacher={() => console.log("handle teacher")}
                teacherName={obj.teacher_name}
                subject_name={obj.subject_name}
                mst_session_course_id={obj.mst_session_course_id}
              />
            ))} */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
