/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 *
 */
import React, { useEffect, useState } from "react";
import { constants } from "../../../constants/constants";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import Navbar from "../../../shared/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { TopBarComponent } from "../../../components/topBarComponent/topBarComponent";
import "../liveTuition.scss";
import { services } from "../../../services/api_service";
import LoaderComponent from "../../../shared/loader/loader";
import swal from "sweetalert";
import { messageUtil } from "../../../shared/utils/messages";
import icon from "../../../assets/images/books.jpg";
import {
  checkOrientation,
  getFromLocalStorage,
  getWindowDimensions,
  getxsValue,
  imageReplace,
  setToLocalStorage,
} from "../../../shared/utils/utilityFunctions";
import { TeacherDetailsModal } from "../../../components/uiComponents/modals";
function createMarkup(value: string) {
  return {
    __html: value,
  };
}

/** Actual LiveTuitionDetailsPage function starts here **/
export function LiveTuitionDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [stateData, setStateData] = useState(location?.state);
  const [purchased, setPurchased] = useState(false);
  const [showPaymentOption, setShowPaymentOption] = useState(false);
  const [purchaseUrl, setPurchaseUrl] = useState("");

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [profileData, setProfileData] = useState({
    userName: "",
    name: "",
    zohoCustomerId: "",
  });
  const [courseData, setCourseData] = useState({
    course_name: "",
    subject_name: "",
    room_size: "",
    course_name_trimmed: "",
    aliasSubject: "",
    package_name: "",
    price: "",
    course_description: "",
    image_file_path: "",
    course_id: "",
    content_uuid:""
  });
  const [batchData, setBatchData] = useState([
    {
      start_date: "",
      end_date: "",
      sessionData: [],
      BatchId: "",
    },
  ]);
  const [loader, setLoader] = useState(true);
  const [sessions, setSessions] = useState([{ mst_session_batch_id: "",teacher_name:"" }]);

  function handleClickBc(prop: string) {
    navigateTo("/live-tuition");
  }
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      style={{ cursor: "pointer" }}
      // href="/learn-on"
      onClick={() => handleClickBc("stage1")}
    >
      Live Tuition
    </Link>,
    <Typography key="3" color="text.primary">
      {courseData.aliasSubject
        ? courseData.course_name_trimmed
        : courseData.course_name}
    </Typography>,
  ];
  useEffect(() => {
    validateLoggedIn();
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [sessions]);

  useEffect(() => {
    function fetchCourseDetails() {
      setLoader(true);

      Promise.all([
        services.getCourseById(courseId, profileData.userName,profileData.name),
        services.getBatchesByCourseId(courseId),
      ])
        .then(function (responses) {
          let responseOfgetCourseById = responses[0];
          let responseOfgetBatchesByCourseId = responses[1];
          console.log(responseOfgetCourseById);
          if (
            responseOfgetCourseById.data.hasOwnProperty("message") &&
            responseOfgetCourseById.data.message === "Course got successfull !"
          ) {
            let crseData = responseOfgetCourseById.data.data;
            let crseNameArr = crseData.course_name
              ? crseData.course_name?.split("~")
              : "course";
            if (crseNameArr && crseNameArr.length > 1) {
              crseData["course_name_trimmed"] = crseNameArr[0];
              crseData["aliasSubject"] = crseNameArr[1];
            }
            setCourseData(crseData);
            setPurchased(responseOfgetCourseById.data.isPurchased)
            if (
              responseOfgetCourseById.data.hasOwnProperty("message") &&
              responseOfgetCourseById.data.message ===
                "Course got successfull !"
            ) {
              setBatchData(responseOfgetBatchesByCourseId.data.data);
            }
          }
          // setSubjectList(responseOfgetCourseById.data);
        })
        .catch((e) => {
          console.error(e);
          swal("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    }
    let courseId = stateData;
    if (courseId && profileData.userName && profileData.name) fetchCourseDetails();
  }, [profileData.userName]);

  useEffect(() => {
    console.log("fetching session...");
    console.log(batchData);
    setSessions([{ mst_session_batch_id: "", teacher_name:"" }]);
    if (batchData && batchData[0] && batchData[0].BatchId) {
      services.getSessionByBatchId(batchData[0].BatchId).then((res: any) => {
        setLoader(false);

        console.log(res.data.data);
        let sessionsUnSorted = res.data.data;
        let sessionSorted = sessionsUnSorted.sort(function (x: any, y: any) {
          return x.SessionStartTime - y.SessionStartTime;
        });
        batchData[0].sessionData = sessionSorted;
        setSessions(sessionSorted);
      });
    }
  }, [batchData]);
  const windowFlag =
    windowDimensions.width < constants.WINDOW_THRESHOLD ? false : true;
  const hideNavBar =
    windowDimensions.width < constants.HIDE_NAVBAR_THRESHOLD ? true : false;
  function navigateTo(path: string) {
    navigate(path);
  }

  const validateLoggedIn = () => {
    var val = localStorage.getItem("profileData");

    if (typeof val !== "undefined" && val !== null)
      setProfileData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };

  function getSessions(BatchId: string, index: number) {
    if (!batchData[index].sessionData) {
      setLoader(true);
      services
        .getSessionByBatchId(BatchId)
        .then((res: any) => {
          setLoader(false);

          console.log(res.data.data);
          let sessionsUnSorted = res.data.data;
          let sessionSorted = sessionsUnSorted.sort(function (x: any, y: any) {
            return x.SessionStartTime - y.SessionStartTime;
          });
          console.log(sessionSorted);

          batchData[index].sessionData = sessionSorted;
          setBatchData(batchData);
          setSessions(sessionSorted);
        })
        .catch((e) => {
          console.error(e);
          swal("error", messageUtil.SOMETHING_WENT_WRONG);
        });
    } else {
      setSessions(batchData[index].sessionData);
    }
  }
  function buyNow(param: string) {
    // setLoader(true);
    // let apiData = {
    //   courseId: courseData.course_id,
    //   batchId: batchData[0].BatchId,
    //   userName: profileData.userName,
    //   name: profileData.name,
    // };
    if (param === "add") {
      //   services.buyCourse(apiData).then((res) => {
      //   swal({title:'Info',
      //   html:"Test Html"
      // })
      // setLoader(false);
      // if (res.failed) {
      //   swal("error", messageUtil.SOMETHING_WENT_WRONG);
      // } else {
      //   swal("success", res.message);
      //   setPurchased(true);
      // }
      // });
    } else {
      // setLoader(false);
      // Buy now api should be implemented here
    }
  }
  const [open, setOpen] = React.useState(false);
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);
  const handleOpenTeacher = () => setTeacherDetails(true);
  const handleClose = () => {
    setOpen(false);
    setTeacherDetails(false);
  };
  return (
    loader ? <LoaderComponent /> :
    <Grid container style={{ maxWidth: "98vw" }} className="fade-in-animate">
      <TeacherDetailsModal
        open={openTeacherDetails}
        setOpen={setOpen}
        // handleOpen={handleOpen}
        handleClose={handleClose}
      />
      <Grid item xs={getxsValue("navbar", windowFlag, hideNavBar)}>
        <Navbar path={"live-tuition"} />
      </Grid>
      <Grid item xs={getxsValue("outlet", windowFlag, hideNavBar)}>
        <TopBarComponent search={true} logo={false} profileData={profileData} />
        <Grid container>
          <Grid item sm={12}>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              style={{ marginTop: "28px", marginLeft: "25px" }}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid container item xs={12}>
            <Grid sm={12} item container>
              <Grid
                item
                container
                md={8}
                xs={12}
                className="courseNameContainer"
                style={{ marginTop: "15px" }}
              >
                <Grid item xs={8}>
                  <div className="labels sub">
                    {courseData.subject_name === "Science"
                      ? courseData.subject_name +
                        " (" +
                        courseData.aliasSubject +
                        ") "
                      : courseData.subject_name}{" "}
                  </div>
                  <div className="labels course">
                    {courseData.course_name_trimmed
                      ? courseData.course_name_trimmed
                      : courseData.course_name}{" "}
                  </div>
                  <div className="labels date">
                    {batchData[0].start_date} -{" "}
                    {batchData[batchData.length - 1].end_date}
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <img
                    src={
                      courseData.image_file_path
                        ? imageReplace(courseData.image_file_path)
                        : icon
                    }
                    alt="Logo"
                    height={120}
                    width={250}
                    style={{ marginTop: 10, borderRadius: 8 }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={3}
                xs={11}
                className="priceContainer"
                style={{ marginLeft: "15px", marginTop: "15px" }}
                spacing={1}
              >
                {/* change price to 0 */}
                {
                  // !purchased && courseData.price === "1" ? (
                  <Grid
                    // className="buyNow"
                    item
                    xs={12}
                    onClick={() => buyNow("add")}
                  >
                    <span className="labels extraDetails">
                      For Admission details,
                    </span>{" "}
                    <br />
                    <span>
                      {" "}
                      Phone: <a href="tel:+96569646481">+965 696 46481</a>
                    </span>{" "}
                    <br />
                    <span className="labels extraDetails">
                      {" "}
                      Email :{" "}
                      <a href="mailto: contact@goscorelearning.com">
                        contact@goscorelearning.com
                      </a>
                    </span>
                  </Grid>
                  // ) : !purchased ? (
                  //   <>
                  //     {" "}
                  //     <Grid item xs={12}>
                  //       <Grid
                  //         className="buyNow"
                  //         item
                  //         xs={12}
                  //         onClick={() => buyNow("add")}
                  //       >
                  //         Buy for 15000 /Year
                  //   </Grid>
                  // </Grid>
                  //     <Grid item xs={12}>
                  //       or
                  // </Grid>
                  //     <Grid
                  //       className="buyNow"
                  //       item
                  //       xs={12}
                  //       onClick={() => buyNow("add")}
                  //     >
                  //       Buy for 1250 /Month
                  //   </Grid>
                  //   </>
                  // ) : (
                  //   <Grid className="buyNow green" item xs={12}>
                  //     Purchased ✔
                  //   </Grid>
                  // )
                }

                {/* <Grid className="labels 
                " item xs={12}>
                  ₹ {courseData.price}
                </Grid>
                {!purchased && courseData.price === "0" ? (
                  <Grid
                    className="buyNow"
                    item
                    xs={12}
                    onClick={() => buyNow("add")}
                  >
                    Add to my account
                  </Grid>
                ) : !purchased ? (
                  <Grid
                    className="buyNow"
                    item
                    xs={12}
                    onClick={() => buyNow("buy")}
                  >
                    Buy Now
                  </Grid>
                ) : (
                  <Grid className="buyNow green" item xs={12}>
                    Purchased ✔
                  </Grid>
                )} */}
                {/* <div className="labels amount">₹ 240</div>
                <div className="buyNow">Buy Now</div> */}
              </Grid>
            </Grid>
            <Grid sm={12} item container>
              <Grid item xs={12} sm={8} style={{ marginTop: "15px" }}>
                {sessions.map((session: any, index: number) => (
                  <Accordion
                    onChange={(e, expanded) => {
                      if (expanded) {
                        // accordionOpened(session.BatchId, index);
                        // can implement a logic if reqquired.
                      }
                    }}
                    className="sessionAccordian"
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="labels sessionName">
                        {session.chapter_name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs={12} container>
                        <Grid item xs={12}>
                          <Typography className="labels sessionDate">
                            {session.session_date} {session.session_day}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider variant="middle" />
                      <Grid item xs={12} container style={{ padding: "8px" }}>
                        <Grid container item xs={10}>
                          <Grid item xs={12}>
                            <Typography className="labels sessionTime">
                              {session.from_time} - {session.to_time}{" "}
                              {session.topic_name
                                ? "(" + session.topic_name + ")"
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography
                            className="labels TeacherName"
                            onClick={handleOpenTeacher}
                          >
                            {session.teacher_name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Grid
                  xs={12}
                  className="detailsContainer"
                  style={{ marginLeft: "15px", marginTop: "15px" }}
                >
                  <div className="labels extraDetails">
                    Package : {courseData.package_name}
                  </div>
                  <div className="labels extraDetails">Description </div>
                  <div
                    className="labels descriptionCntr"
                    dangerouslySetInnerHTML={createMarkup(
                      courseData.course_description
                    )}
                  ></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className="detailsContainer"
                  style={{ marginLeft: "15px", marginTop: "15px" }}
                >
                  <BatchList
                    batchData={batchData}
                    sessions={sessions}
                    getSessions={getSessions}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BatchList(props: any) {
  useEffect(() => {}, []);
  return (
    <>
      <div className="labels extraDetails">Batches </div>
      {props.batchData.map((batch: any, index: number) => {
        if (batch.BatchId === props.sessions[0]?.mst_session_batch_id) {
          return (
            <div
              className="batchListSelected"
              onClick={() => props.getSessions(batch.BatchId, index)}
            >
              {batch.batch_name}
            </div>
          );
        } else {
          return (
            <div
              className="batchList"
              onClick={() => props.getSessions(batch.BatchId, index)}
            >
              {batch.batch_name}
            </div>
          );
        }
      })}
    </>
  );
}
