import { Grid, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";

import { ReactComponent as DoubtboxLogo } from "../../assets/logo/doubtbox_logo.svg";
import AddIcon from "@mui/icons-material/Add";
import "./Learners.scss";
import { deepPurple } from "@mui/material/colors";
export function SwitchLearnersPage() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({ profile: [] });

  useEffect(() => {
    validateLoggedIn();
  }, []);
  const validateLoggedIn = () => {
    var val = localStorage.getItem("userData");

    if (val !== undefined && val !== null) setUserData(JSON.parse(val));
    else {
      navigateTo("/login");
    }
  };
  // const handleChange =
  //   (prop: keyof PasswordState) =>
  //   (event: React.ChangeEvent<HTMLInputElement>) => {
  //     setValues({ ...values, [prop]: event.target.value });
  //     console.log(prop);
  //   };

  function navigateTo(path: string) {
    navigate(path);
  }
  function deadCall(data: any) {
    // used to call a dead function that does nothing.
    // (just to avoid errors)
    return false;
  }
  console.log(userData.profile);
  function selectLearner(item: any) {
    console.log(item);
    localStorage.setItem("profileData", JSON.stringify(item));
    navigateTo("/dashboard");
  }
  return (
    <Grid container>
      <DoubtboxLogoContainer navigateTo={deadCall} />
      <div className="settingsContainer" style={{ marginTop: "25vh" }}>
        <Grid>
          <span className="chooseLabel">Choose your learner</span>
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          justifyContent="center"
        >
          {userData.profile.map((item: { name: string; standard: any }) => (
            <Grid
              container
              item
              xs={6}
              sm={2}
              md={2}
              lg={1}
              className="clContainer"
              key={item.name}
              style={{ marginTop: "30px", cursor: "pointer" }}
              onClick={() => selectLearner(item)}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Avatar
                  variant="square"
                  sx={{ width: 100, height: 100, bgcolor: deepPurple[500] }}
                  alt={item.name}
                  src="../xyz.jpg"
                />
              </Grid>
              <Grid item xs={12} className="childName">
                {item.name}
              </Grid>
              <Grid item xs={12} className="classLabel">
                Class &nbsp;{item.standard}
              </Grid>
            </Grid>
          ))}
          {/* <Grid
            container
            item
            md={1}
            xs={6}
            className="clContainer"
            style={{ marginTop: "20px", cursor: "pointer" }}
          >
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Avatar sx={{ width: 100, height: 100 }} variant="square">
                <AddIcon sx={{ width: 50, height: 50 }} />
              </Avatar>
            </Grid>
            <Grid item xs={12} className="childName">
              Add a Kid
            </Grid>
          </Grid> */}
        </Grid>
      </div>
    </Grid>
  );
}
function DoubtboxLogoContainer(props: { navigateTo: any }) {
  return (
    <div className="floatingLogo">
      <DoubtboxLogo onClick={() => props.navigateTo("/dashboard")} />
    </div>
  );
}
