import { Avatar, Checkbox, CircularProgress, Grid } from "@mui/material";
import "./uiComponents.scss";
import React, { useEffect } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
// import { ReactComponent as BiologyLogo } from "../../assets/icons/microScope.svg";
// import { ReactComponent as ChemistryLogo } from "../../assets/icons/flask.svg";
import { ReactComponent as CompleteLogo } from "../../assets/icons/complete.svg";
import { ReactComponent as FabRightArrow } from "../../assets/icons/fabRightArrow.svg";
import { ReactComponent as UnlockIcon } from "../../assets/icons/unlock.svg";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";
import { SubjectTags } from "./subjectTags";
import { useNavigate } from "react-router-dom";
import useState from "react";
import swal from "sweetalert";
import {
  // evsCondition,
  getButtonValue,
  getInitials,
  getTimer,
  imageReplace,
} from "../../shared/utils/utilityFunctions";
import { AsyncLoaderComponent } from "../../shared/loader/loader";
import { TuitionRoomInterface } from "../../constants/interfaces";
import { constants } from "../../constants/constants";
import { CouponModal } from "../uiComponents/modals";
import { deepPurple } from "@mui/material/colors";
const elemPrefix = "test";
const getId = (index: any) => `${elemPrefix}${index}`;
const getItems = () =>
  Array(10)
    .fill(0)
    .map((_, ind) => ({ id: getId(ind) }));

export function RecommendedLiveTuitions(props: {
  sessionData: any;
  joinNow: any;
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [items] = React.useState(getItems);
  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = (childProps: any) => {
      navigate("/live-tuition/details", {
        state: childProps.mst_session_course_id,
      });
  };
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);
  const handleOpenTeacher = () => setTeacherDetails(true);

  return (
    <Grid>
      <Grid>
        <span className="recommendedLabelText1">Recommended Tuitions</span>
        <br />
        <span className="recommendedLabelText2"> Grab your seats!</span>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="recommendedscrollContainer"
        >
          {props.sessionData.length > 0 ? (
            props.sessionData.map((props: any) => (
              <LiveTuitionCard
                itemId={props.content_uuid} // NOTE: itemId is required for track items
                title={props.course_name}
                key={props.content_uuid}
                onClick={handleClick(props.content_uuid)}
                selected={isItemSelected(props.content_uuid)}
                button={"More Details"}
                handleOpen={handleOpen}
                handleOpenTeacher={handleOpenTeacher}
                teacherName={props.teacher_name}
                date={props.session_date}
                time={props.from_time}
                seats={"4/5"}
                subject_name={props.subject_name}
                mst_session_course_id={props.mst_session_course_id}
                mst_session_subject_id={props.mst_session_subject_id}
                mst_session_textbook_id={props.mst_session_textbook_id}
                textbook_name={props.textbook_name}
                mst_session_chapter_id={props.mst_session_chapter_id}
                chapter_name={props.chapter_name}
                teacher_id={props.teacher_id}
                teacher_name={props.teacher_name}
                SessionStartTime={props.SessionStartTime}
              />
            ))
          ) : (
            <div className="noSessions">
              {" "}
              <p style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}>
                No sessions for you now.
              </p>
            </div>
          )}
        </ScrollMenu>
      </Grid>
    </Grid>
  );
}

export function UpcommingSessions(props: { sessionData: any; joinNow: any }) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [items] = React.useState(getItems);
  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };

  const [time, setTime] = React.useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (childProps: any, button?: any) => {
    console.log(button);
    //  props.joinNow(childProps)
    // let sessionButtonStatus = button;
    // if (sessionButtonStatus === constants.MORE_DETAILS) {
    //   navigate("/live-tuition/details", {
    //     state: childProps.mst_session_course_id,
    //   });
    // } else {
      console.log("joining to tuition app...");
      props.joinNow(childProps);
    // }
  };
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);
  const handleOpenTeacher = () => setTeacherDetails(true);

  const handleClose = () => setOpen(false);
  return (
    <Grid>
      <Grid>
        <span className="recommendedLabelText1">Upcoming Live Sessions</span>
        <br />
        <span className="recommendedLabelText2">
          {" "}
          Join to your sessions now!
        </span>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="recommendedscrollContainer"
        >
          {props.sessionData && props.sessionData.length > 0 ? (
            props.sessionData.map((obj: any) => (
              <LiveTuitionCard
                itemId={obj.content_uuid} // NOTE: itemId is required for track items
                title={obj.course_name}
                key={obj.content_uuid}
                onClick={handleClick(obj.content_uuid)}
                selected={isItemSelected(obj.content_uuid)}
                button={getButtonValue(
                  obj?.from_time,
                  obj?.to_time,
                  obj?.session_date
                )}
                cardFor={constants.UPCOMING}
                handleOpen={handleOpen}
                handleOpenTeacher={handleOpenTeacher}
                teacherName={obj.teacher_name}
                date={obj.session_date}
                time={obj.from_time}
                seats={"4/5"}
                subject_name={obj.subject_name}
                mst_session_course_id={obj.mst_session_course_id}
                mst_session_subject_id={obj.mst_session_subject_id}
                mst_session_textbook_id={obj.mst_session_textbook_id}
                textbook_name={obj.textbook_name}
                mst_session_chapter_id={obj.mst_session_chapter_id}
                chapter_name={obj.chapter_name}
                teacher_id={obj.teacher_id}
                teacher_name={obj.teacher_name}
                SessionStartTime={obj.SessionStartTime}
              />
            ))
          ) : (
            <div className="noSessions">
              {" "}
              <p style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}>
                No sessions for you now.
              </p>
            </div>
          )}
        </ScrollMenu>
      </Grid>
    </Grid>
  );
}

export function FreeLiveTuitions(props: {
  sessionData: any;
  joinNow: any;
  setFreetrialFLag: any;
}) {
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState([]);
  // const [position, setPosition] = React.useState(0);
  const isItemSelected = (id: any) => !!selected.find((el) => el === id);
  const [items] = React.useState(getItems);
  const handleClick = (id: any) => () => {
    const itemSelected = isItemSelected(id);

    setSelected((currentSelected) =>
      itemSelected
        ? currentSelected.filter((el) => el !== id)
        : currentSelected.concat(id)
    );
  };

  const [time, setTime] = React.useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (childProps: any, button?: any) => {
    //  props.joinNow(childProps)
    // let sessionButtonStatus = childProps.button;
    let buttonValue = button;
    console.log(buttonValue);
    // if (buttonValue === constants.MORE_DETAILS) {
      navigate("/live-tuition/details", {
        state: childProps.mst_session_course_id,
      });
    // } else if (buttonValue === constants.JOIN_NOW) {
    //   console.log("joining to tuition app...");
    //   props.setFreetrialFLag(childProps);
    // } else {
    //   swal({
    //     title: "Live tuition not started!",
    //     text:
    //       "Hey! your FREE Live session will start at " +
    //       childProps.date +
    //       " " +
    //       childProps.time,
    //     icon: "info",
    //   });
    // }
  };
  const [openTeacherDetails, setTeacherDetails] = React.useState(false);
  const handleOpenTeacher = () => setTeacherDetails(true);

  const handleClose = () => setOpen(false);
  return (
    <Grid>
      <Grid>
        <span className="recommendedLabelText1">Free Live Sessions</span>
        <br />
        <span className="recommendedLabelText2">
          {" "}
          Join to your free sessions now!
        </span>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="recommendedscrollContainer"
        >
          {props.sessionData.length > 0 ? (
            props.sessionData.map((props: any) => (
              <LiveTuitionCard
                itemId={props.content_uuid} // NOTE: itemId is required for track items
                title={props.course_name}
                key={props.content_uuid}
                onClick={handleClick(props.content_uuid)}
                selected={isItemSelected(props.content_uuid)}
                button={constants.FREE_SESSION}
                handleOpen={handleOpen}
                handleOpenTeacher={handleOpenTeacher}
                teacherName={props.teacher_name}
                date={props.session_date}
                time={props.from_time}
                seats={"4/5"}
                subject_name={props.subject_name}
                mst_session_course_id={props.mst_session_course_id}
                mst_session_subject_id={props.mst_session_subject_id}
                mst_session_textbook_id={props.mst_session_textbook_id}
                textbook_name={props.textbook_name}
                mst_session_chapter_id={props.mst_session_chapter_id}
                chapter_name={props.chapter_name}
                teacher_id={props.teacher_id}
                teacher_name={props.teacher_name}
                SessionStartTime={props.SessionStartTime}
              />
            ))
          ) : (
            <div className="noSessions">
              {" "}
              <p style={{ fontSize: 16, fontWeight: "bold", padding: 25 }}>
                No sessions for you now.
              </p>
            </div>
          )}
        </ScrollMenu>
      </Grid>
    </Grid>
  );
}
function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
      Left
    </Arrow>
  );
}
function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
      Right
    </Arrow>
  );
}

// each card for Recommended Live Tuition for you pannel
// For Dashboard only
export function LiveTuitionCard(props: any) {
  const visibility = React.useContext(VisibilityContext);
  let titleArr = props.title?.split("~");
  let modTitle = props.title;
  if (titleArr && titleArr.length > 1) {
    modTitle = titleArr[0];
  }
  let buttonValue = getTimer(props.SessionStartTime);
  return (
    <div
      className="recommendedCard"
      onClick={() => props.onClick(visibility)}
      tabIndex={0}
    >
      <div className="recContents">
        <Grid container>
          <Grid container item xs={8}>
            {/* should use background color for different subjects */}
            {/* <div
              className="subLogoBackdrop"
              style={{ backgroundColor: "#FEF0DF" }}
            >
              <ChemistryLogo
                className="subjectLogo"
                style={{ width: "20px" }}
              />
            </div> */}
          </Grid>
          {/* <Grid container item xs={4}>
            <div className="availableSeats">
              <UsersAvatar /> {seats} &nbsp; Seats
            </div>
          </Grid> */}
          {titleArr && titleArr[1] ? (
            <Grid container item xs={4}>
              <div className="availableSeats">{titleArr[1]}</div>
            </Grid>
          ) : (
            <Grid container item xs={4}>
              <div className="availableSeats">{props.subject_name}</div>
            </Grid>
          )}
        </Grid>
        <div className="courseName hideScrollbar">{modTitle}</div>

        <Grid container>
          <Grid container item xs={6}>
            <div>
              <div className="courseDetailsLabel">Instructor</div>
              <div
                className="courseDetailsValue"
                style={{ cursor: "pointer" }}
                onClick={props.handleOpenTeacher}
              >
                {props.teacherName}
              </div>
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div>
              <div className="courseDetailsLabel">Date</div>
              <div className="courseDetailsValue">{props.date}</div>
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div>
              <div className="courseDetailsLabel">Time</div>
              <div className="courseDetailsValue">{props.time}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container className="cardFooter">
          <Grid container item xs={6}>
            {/* {buttonValue === constants.JOIN_NOW &&<div className="moreDetails" onClick={()=>props.handleOpen(props, constants.MORE_DETAILS)}>
              More Details
            </div>} */}
          </Grid>
          {props.button === constants.FREE_SESSION ? (
          <Grid container item xs={6}>
              {buttonValue === constants.JOIN_NOW ? (
                <div
                  className="joinButton now"
                  onClick={() => props.handleOpen(props, buttonValue)}
                >
                  {buttonValue}
                </div>
              ) : (
                <div
                  className="joinButton"
                  onClick={() => props.handleOpen(props, buttonValue)}
                >
                  {buttonValue}
                </div>
              )}
            </Grid>
          ) : (
            <Grid container item xs={6}>
              {
                props.button === constants.JOIN_NOW?
              <div
                className="joinButton now"
                onClick={() => props.handleOpen(props, buttonValue)}
              >
                {buttonValue}
              </div>: <div
                className="joinButton"
                onClick={() => props.handleOpen(props, buttonValue)}
              >
              {props.button}
            </div>
              }
             
          </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
}

export function LiveTuitionCommonCard(props: any) {
  // console.log(props)
  const visibility = React.useContext(VisibilityContext);
  let titleArr = props.title?.split("~");
  let trimmed_coursename = props.title;
  if (titleArr && titleArr.length > 1) {
    trimmed_coursename = titleArr[0];
  }

  return (
    <div
      className="recommendedCard"
      onClick={() => props.onClick(visibility)}
      tabIndex={0}
    >
      <div className="recContents">
        <Grid container>
          <Grid container item xs={8}>
            {/* should use background color for different subjects */}
            {/* <div
              className="subLogoBackdrop"
              style={{ backgroundColor: "#FEF0DF" }}
            > */}
              {/* <BiologyLogo className="subjectLogo" style={{width:"20px"}} /> */}
              {/* <ChemistryLogo
                className="subjectLogo"
                style={{ width: "20px" }}
              /> */}
            {/* </div> */}
          </Grid>

          <Grid container item xs={4}>
            <div className="availableSeats">{props.subject_name}</div>
          </Grid>
        </Grid>
        <div className="courseName hideScrollbar">
          {trimmed_coursename ? trimmed_coursename : props.title}
        </div>

        <Grid container>
          <Grid container item xs={6}>
            <div>
              <div className="courseDetailsLabel">Instructor</div>
              <div
                className="courseDetailsValue"
                style={{ cursor: "pointer" }}
                onClick={props.handleOpenTeacher}
              >
                {props.teacherName}
              </div>
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div>
              <div className="courseDetailsLabel">Date</div>
              <div className="courseDetailsValue">{props.date}</div>
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div>
              <div className="courseDetailsLabel">Time</div>
              <div className="courseDetailsValue">{props.time}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container className="cardFooter">
          <Grid container item xs={6}>
           {props.button === constants.JOIN_NOW && <div className="moreDetails" onClick={() => props.handleOpen(props.mst_session_course_id, constants.MORE_DETAILS)}>
              More Details
            </div>}
          </Grid>
          <Grid container item xs={6}>
            <div
              className="joinButton"
              onClick={() => props.handleOpen(props.mst_session_course_id, props.button)}
            >
              {props.button}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

// Arows for Recommended Live Tuition for you swiper
function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: string;
  disabled: any;
  onClick: any;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",
        opacity: disabled ? "0" : "1",
        userSelect: "none",
        height: "25px",
        marginTop: "130px",
        background: "white",
        border: "none",
      }}
    >
      {children === "Right" ? (
        <FabRightArrow />
      ) : (
        <FabRightArrow className="invertFavArrow" />
      )}
    </button>
  );
}

// Cards for Assess me pannel
export function AssessmeLayoutCard(props: {
  selectAssessMe: any;
  assesmeList: any;
}) {
  let list = props.assesmeList;
  if (list && list.length > 0) {
    return (
      <>
        {list.map(
          ({
            id,
            name,
            subjectName,
            thumbnail,
            subject,
            textbook,
            textbookName,
            chapter_no,
          }: {
            id: string;
            name: string;
            subjectName: string;
            thumbnail: string;
            subject: any;
            textbook: string;
            textbookName: string;
            chapter_no: string;
          }) => (
            <Grid
              className="assessmeLayoutCardContainer"
              key={id}
              onClick={() => {
                props.selectAssessMe(
                  subjectName,
                  subject,
                  textbook,
                  textbookName,
                  id,
                  name
                );
              }}
            >
              <Grid container>
                <Grid container item xs={3}>
                  <div className="assessmeLayoutContainer1">
                    {/* <Edit /> */}
                    <Avatar
                      sx={{ width: 40, height: 40, bgcolor: "#0AA245" }}
                      src={imageReplace(thumbnail)}
                    >
                      {getInitials(name)}
                    </Avatar>
                    {/* <img
                      src={imageReplace(thumbnail)}
                      alt="logo"
                      style={{ width: "40px", borderRadius: "50px" }}
                    /> */}
                  </div>
                </Grid>
                <Grid container item xs={7}>
                  <div className="assessmeLayoutContainer2">
                    <div className="chapterName hideScrollBar">{name}</div>
                    <span className="subjectName">{subjectName}</span>
                  </div>
                </Grid>
                <Grid container item xs={1}>
                  <div className="assessmeLayoutContainer3">
                    <FabRightArrow />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      </>
    );
  } else {
    return (
      <div style={{ paddingLeft: "55px" }}>
        <AsyncLoaderComponent parent={"Assessment "} />
      </div>
    );
  }
}

// imported in dashboard
export function LearnOnLayout(props: { selectSubject: any; subjectList: any }) {
  const [items, setItems] = React.useState([]);
  useEffect(() => {
    setItems(props.subjectList);
  }, [props.subjectList]);
  function selectSubject(a: any, b: any) {
    console.log(a, b);
  }
  console.log(items);
  return (
    <Grid>
      <Grid>
        <span className="recommendedLabelText1">
          Enjoy learning with multimedia lessons!
        </span>
        <br />
        <span className="recommendedLabelText2">
          Visualize & learn with animations.
        </span>
      </Grid>
      <Grid container spacing={1}>
        {items ? (
          items.map(
          (item: any) =>
            item.learnOnStatus === "true" && (
              <Grid
                item
                xs={3}
                md={2}
                style={{ width: "10px", margin: "10px" }}
                key={item.id}
                  className="subjectContainer"
              >
                <SubjectTags
                  subject={item.name}
                  key={item.id}
                  id={item.id}
                  selectSubject={props.selectSubject}
                />
              </Grid>
            )
          )
        ) : (
          <div style={{ color: "#3e5269", marginTop: 15, marginLeft: 25 }}>
            No Learn On contents found!
          </div>
        )}
      </Grid>
    </Grid>
  );
}

// imported in assess me page
export function SelectSubjectComponent(props: {
  selectSubject: any;
  subjectList: any;
  status: any;
  profileData?: any;
  caseValue?: string;
}) {
  const [items, setItem] = React.useState([{ id: "", name: "" }]);
  useEffect(() => {
    var temp: any[] = [];
    if (props.subjectList && props.subjectList.length) {
      for (let i = 0; i < props.subjectList.length; i++) {
        let element = props.subjectList[i];
        // if (evsCondition(element, props.profileData,props.caseValue)) element[props.status] = "false";
        // To check status of learn on assess me and lie tuition etc.
        if (element[props.status] === "true") {
          temp.push(element);
        }
      }
    }

    setItem(temp);
  }, [props.subjectList]);

  return (
    <Grid className="subjectLayoutContainer">
      <Grid container spacing={1}>
        {items.map(({ id, name }: { id: string; name: string }) => (
          <Grid
            item
            xs={5}
            md={2}
            style={{ width: "10px", margin: "10px", paddingLeft:'2.3vw' }}
            key={id}
            className="subjectContainer"
          >
            <SubjectTags
              subject={name}
              id={id}
              key={id}
              selectSubject={props.selectSubject}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export function SelectChapter(props: {
  selectChapter: any;
  parent: string;
  chapterList: any;
}) {
  // console.log(props.chapterList);
  const purchased = false;
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  console.log(props.parent);
  function checkParent(dataProps: any) {
    if (dataProps.locked) {
      return swal({
        title: "Selected chapter is locked!",
        text: "Please call +965 696 46481  to unlock",
        icon: "info",
      });
    }

    if (props.parent !== "mocktest") return props.selectChapter(dataProps);
    else console.log("mocktest Clicked");
  }
  const selectedChapterArr: string[] = [];

  const selectedArr: string[] = [];
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    props: any
  ) => {
    let id = props.id;
    let name = props.name;
    // setChecked(event.target.checked);
    console.log(id);
    if (!selectedArr.includes(id)) {
      selectedArr.push(id);
      selectedChapterArr.push(name);
    } else {
      const index = selectedArr.indexOf(id);
      selectedArr.splice(index, 1);
      selectedChapterArr.splice(index, 1);
    }
    localStorage.setItem(
      "selectedChapterArr",
      JSON.stringify(selectedChapterArr)
    );
    console.log(selectedArr);
  };
  function proceed() {
    console.log(selectedArr);
    props.selectChapter(selectedArr);
  }
  const [openCouponModal, setOpenCouponModal] = React.useState(false);
  function openCoupon() {
    setOpenCouponModal(true);
  }

  const handleCloseCouponModal = () => setOpenCouponModal(false);
  return (
    <Grid
      container
      spacing={1}
      style={{ paddingLeft: "35px", marginTop: "15px" }}
    >
           <CouponModal
    open={openCouponModal}
    setOpen={setOpenCouponModal}
   handleClose={handleCloseCouponModal}
  />
      {props.chapterList.map((lsitprops: any) => (
        <>
          <Grid item xs={12} sm={6} md={4} lg={4} key={lsitprops.id}>
            <div
              className="subjectCard"
              onClick={() => {
                checkParent(lsitprops);
              }}
            >
              {props.parent === "mocktest" ? (
                <Checkbox
                  {...label}
                  style={{ position: "absolute", marginLeft: "255px" }}
                  onChange={(e) => {
                    handleChange(e, props);
                  }}
                  disabled={lsitprops.locked}
                />
              ) : props.parent !== "mocktest" && lsitprops.locked === true ? (
                <LockIcon className="lockIcon" />
              ) : (
                <UnlockIcon className="lockIcon" />
              )}

              <div className="subjectCardImage">
               { lsitprops && lsitprops.name&&<Avatar
                  sx={{ width: 52, height: 52, bgcolor: "#074EA2" }}
                  src={imageReplace(lsitprops.thumbnail)}
                >
                  {getInitials(lsitprops.name)}
                </Avatar>}
              </div>
              <div className="subjectCardLabel">{lsitprops.name}</div>
            </div>
          </Grid>
        </>
      ))}
      {props.parent === "mocktest" ? (
        <Grid xs={12} style={{ marginBottom: 45 }}>
          <div className="proceedButton" onClick={proceed}>
            Proceed
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}

export function CompletedLiveTuitionCard(props: any) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div
      className="recommendedCard"
      onClick={() => props.onClick(visibility)}
      tabIndex={0}
    >
      <div className="recContents">
        {/* <div>{title}</div>
        <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        <div>selected: {JSON.stringify(!!selected)}</div> */}

        <Grid container>
          <Grid container item xs={8}>
            {/* should use background color for different subjects */}
            {/* <div
              className="subLogoBackdrop"
              style={{ backgroundColor: "#FEF0DF", width: "auto", padding: 5 }}
            > */}
              {/* <BiologyLogo className="subjectLogo" style={{width:"20px"}} /> */}
              {/* <ChemistryLogo
                className="subjectLogo"
                style={{ width: "20px" }}
              /> */}
            <div
              style={{
                color: "#0e2744",
              }}
            >
                {props.subjectName === "Mathematics"
                  ? props.subjectName
                  : props.scienceSubject}
              </div>
            {/* </div> */}
          </Grid>
          <Grid container item xs={4}>
            <div className="availableSeats">
              <CompleteLogo /> Complete
            </div>
          </Grid>
        </Grid>
        <div className="courseName">{props.title}</div>

        <Grid container className="cardFooter completedFooter">
          <Grid container item xs={6}>
            <div className="sessionDetailChips">{props.teacherName}</div>
          </Grid>
          <Grid container item xs={3}>
            <div className="sessionDetailChips">{props.sessionDate}</div>
          </Grid>
          <Grid container item xs={3}>
            <div className="sessionDetailChips">{props.fromTime}</div>
          </Grid>
        </Grid>
        <Grid container className="cardFooter">
          <Grid container item xs={6}>
            {/* <div className="moreDetails" onClick={handleOpen}>
              More Details
            </div> */}
          </Grid>
          <Grid container item xs={6}>
            <div
              className="joinButton"
              onClick={() => props.showReplay(props.replayUrl)}
            >
              {props.button}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export function NoSessions() {
  return <div style={{ width: "100%", height: 80 }}>No Sessions to show!</div>;
}
