/* Copyright (C) Doubtbox Edutainment (P) Ltd, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 * Written by Rahul krishna <rahul.krishna@doubtbox.com>, November 2022
 */

import "./App.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { constants } from "./constants/constants";

import Layout from "./pages/Layout";
import Dashboard from "./pages/dashboard/Dashboard";
import { PageNotFound } from "./pages/dashboard/404";
import { SignupPage } from "./pages/signup/Signup";
import { LoginPage } from "./pages/login/Login";

import { SettingsPage } from "./pages/settings/Settings";
import { SettingsProfilePage } from "./pages/settings/settingsPages/SettingsProfile";
import { SettingsPasswordPage } from "./pages/settings/settingsPages/settingsPassword";
import { ClassUpgradePage } from "./pages/settings/settingsPages/SettingsClassUpgrade";

import { AssessMePage } from "./pages/assessMe/AssessMe";
import { AssessMeTestPage } from "./pages/assessMe/assessMePages/AssessMeTestPage";

import { LearnOnPage } from "./pages/learnOn/LearnOn";
import { LearnOnContentsPage } from "./pages/learnOn/learnOnPages/ContentsPage";

import { LiveTuitionPage } from "./pages/liveTuition/liveTuition";
import { ReportPage } from "./pages/reports/Reports";

import { RecordedVideosPage } from "./pages/recordedVideos/RecordedVideos";

import { LiveTuitionPurchased } from "./pages/liveTuition/liveTuitionPages/LiveTuitionPurchased";
import { LiveTuitionRecommended } from "./pages/liveTuition/liveTuitionPages/LiveTuitionRecommended";
import { LiveTuitionPrevious } from "./pages/liveTuition/liveTuitionPages/LiveTuitionPrevious";

import { SwitchLearnersPage } from "./pages/learners/Learners";
import { LiveTuitionDetailsPage } from "./pages/liveTuition/liveTuitionPages/LiveTuitionDetailsPage";
import { AssessMeReportPage } from "./pages/reports/reportsPages/AssessMeReportsPage";
import { AssessMeOverallReportPage } from "./pages/reports/reportsPages/AssessMeOverallReportPage";
import { WorkSheetsPagePage } from "./pages/worksheets/WorkSheets";
import { RecordedVideoContents } from "./pages/recordedVideos/recordedVideosPages/RecordedContentsPage";
import { MockTestsPage } from "./pages/mockTest/MockTest";
import { SearchPage } from "./pages/search/Search";
import { MyOrdersPage } from "./pages/myOrders/MyOrdersPage";
import { LiveTuitionPlayback } from "./pages/liveTuition/liveTuitionPages/LiveTuitionPlayback";
import { WorksheetsContents } from "./pages/worksheets/worksheetsPages/WorksheetsContentPage";
import { AssessMeQnReportPage } from "./pages/reports/reportsPages/AssessMeQnReport";



function App() {
  console.warn(
    `Running on version ${constants.VERSION}, update released on ${constants.RELEASE_DATE}`
  );
  let hostName = window.location.hostname;
  /*removing console logs in production server*/
  if (
    process.env.NODE_ENV === "production" &&
    (hostName === constants.LIVE_HOSTNAME ||
      hostName === constants.GOSCORE_HOSTNAME)
  )
    window.console.log = () => {};
  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Navigate to="/login" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="signup" element={<SignupPage />} />

          <Route path="settings" element={<SettingsPage />} />
          <Route path="settings/profile" element={<SettingsProfilePage />} />
          <Route path="settings/password" element={<SettingsPasswordPage />} />
          <Route path="settings/class-upgrade" element={<ClassUpgradePage />} />
          

          <Route path="assess-me" element={<AssessMePage />} />
          <Route path="assess-me/test" element={<AssessMeTestPage />} />
          {/* <Route path="assess-me/report" element={<AssessMeReportPage />} /> */}
          

          <Route path="live-tuition" element={<LiveTuitionPage />} />
          <Route
            path="live-tuition/purchased"
            element={<LiveTuitionPurchased />}
          />
          <Route
            path="live-tuition/recommended"
            element={<LiveTuitionRecommended />}
          />
          <Route
            path="live-tuition/completed"
            element={<LiveTuitionPrevious />}
          />
           <Route
            path="live-tuition/completed/playback"
            element={<LiveTuitionPlayback />}
          />
          <Route
            path="live-tuition/details"
            element={<LiveTuitionDetailsPage />}
          />

          <Route path="learn-on" element={<LearnOnPage />} />
          <Route path="learn-on/contents" element={<LearnOnContentsPage />} />

          <Route path="recorded-videos" element={<RecordedVideosPage />} />
          <Route path="recorded-videos/contents" element={<RecordedVideoContents />} />

          <Route path="mocktest" element={<MockTestsPage />} />
          
          <Route path="worksheets" element={<WorkSheetsPagePage />} />
          <Route path="worksheets/contents" element={<WorksheetsContents />} />

          <Route path="reports" element={<ReportPage />} />
          <Route path="reports/assess-me-report" element={<AssessMeReportPage />} />
          <Route path="reports/overall-report" element={<AssessMeOverallReportPage />} />
          <Route path="reports/question-report" element={<AssessMeQnReportPage />} />

         
          <Route path="select-learner" element={<SwitchLearnersPage />} />

          <Route path="search" element={<SearchPage />} />

          <Route path="my-orders" element={<MyOrdersPage />} />

          
          <Route path="404" element={<PageNotFound />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
